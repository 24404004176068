import type { FC } from 'react';
import PropTypes from 'prop-types';

interface LogoProps {
  emblemOnly?: boolean;
  variant: 'light' | 'dark';
  size?: 'default' | 'large';
}

export const Logo: FC<LogoProps> = (props) => {
  const { emblemOnly, variant, size } = props;

  const color = variant === 'light' ? '#ffffff' : '#000000';
  const viewbox = emblemOnly ? '0 0 51 51' : '0 0 440 51';
  const width = emblemOnly ? 30 : 210;

  const ratio = size === 'large' ? 2.25 : 1;

  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewbox}
      width={ratio * width}
      style={{ marginBottom: '-0.25em' }}
    >
      <title>Veracity Protocol Logo 2020</title>
      <g>
        <g>
          {!emblemOnly && (
            <>
              <path
                fillRule="evenodd"
                fill={color}
                d="M66.2 31.44C66.83 31.64 67.26 31.78 67.48 31.85C67.08 32.87 66.53 33.88 65.84 34.88C65.15 35.89 64.28 36.78 63.22 37.56C62.17 38.34 60.93 38.97 59.51 39.44C58.09 39.91 56.43 40.15 54.54 40.15C52.57 40.15 50.76 39.8 49.1 39.11C47.44 38.42 46.01 37.43 44.82 36.16C43.62 34.89 42.69 33.38 42.01 31.63C41.33 29.89 40.99 27.94 40.99 25.79C40.99 23.67 41.31 21.74 41.93 19.97C42.55 18.2 43.44 16.68 44.6 15.41C45.75 14.13 47.13 13.15 48.74 12.46C50.35 11.77 52.13 11.42 54.1 11.42C56.07 11.42 57.83 11.74 59.4 12.38C60.96 13.01 62.31 13.92 63.44 15.08C64.56 16.24 65.43 17.65 66.03 19.31C66.63 20.96 66.93 22.81 66.93 24.85C66.93 25.32 66.92 25.8 66.88 26.27C66.85 26.74 66.79 27.27 66.72 27.85L48.48 27.85C48.52 28.73 48.7 29.55 49.03 30.31C49.36 31.07 49.79 31.74 50.34 32.3C50.88 32.86 51.51 33.3 52.22 33.61C52.93 33.92 53.7 34.07 54.54 34.07C55.63 34.07 56.75 33.79 57.9 33.22C59.05 32.66 60.02 31.48 60.82 29.7C61.04 29.77 61.46 29.91 62.1 30.11C62.74 30.32 63.42 30.53 64.15 30.77C64.88 31.01 65.56 31.22 66.2 31.44ZM48.86 22.56L59.13 22.56C58.99 21.03 58.48 19.81 57.63 18.9C56.77 17.99 55.58 17.53 54.05 17.53C52.52 17.53 51.3 18.03 50.39 19.03C49.48 20.03 48.97 21.2 48.86 22.56Z"
              />
              <path
                fill={color}
                d="M87.24 11.42C87.73 11.44 88.22 11.46 88.69 11.5L88.69 17.62L84.81 17.62C81.57 17.62 79.95 20.02 79.95 24.83L79.95 39.58L72.58 39.58L72.58 11.95L79.41 11.95L79.96 14.63C80.5 13.79 81.2 13.05 82.06 12.39C82.91 11.74 84.16 11.41 85.8 11.41C86.27 11.41 86.76 11.42 87.24 11.42Z"
              />
              <path
                fillRule="evenodd"
                fill={color}
                d="M118.88 33.86C119.19 33.8 119.38 33.76 119.45 33.72L119.45 39.51C118.36 39.8 117.49 39.96 116.83 40C116.18 40.03 115.65 40.05 115.25 40.05C113.87 40.05 112.77 39.71 111.95 39.04C111.13 38.36 110.56 37.53 110.23 36.55C109.94 36.95 109.53 37.36 109 37.78C108.47 38.2 107.85 38.58 107.14 38.93C106.43 39.27 105.65 39.56 104.79 39.8C103.93 40.03 103.01 40.15 102.03 40.15C98.82 40.15 96.31 39.32 94.49 37.66C92.67 36 91.76 33.82 91.76 31.13C91.76 30.04 92.01 28.98 92.52 27.96C93.03 26.94 93.78 26.03 94.76 25.23C95.74 24.43 96.95 23.78 98.39 23.29C99.83 22.8 101.47 22.55 103.33 22.55L109.5 22.55L109.5 21.62C109.5 21.22 109.43 20.78 109.28 20.31C109.14 19.84 108.87 19.39 108.49 18.97C108.11 18.55 107.6 18.2 106.96 17.93C106.32 17.66 105.51 17.52 104.53 17.52C103.26 17.52 102.19 17.86 101.34 18.53C100.49 19.21 100.02 20.24 99.95 21.62C98.75 21.4 97.54 21.16 96.32 20.91C95.1 20.66 93.87 20.42 92.63 20.2C92.85 18.67 93.32 17.35 94.05 16.24C94.77 15.13 95.68 14.22 96.75 13.51C97.82 12.8 99.02 12.28 100.35 11.93C101.68 11.58 103.07 11.41 104.53 11.41C108.39 11.41 111.4 12.33 113.57 14.17C115.74 16.01 116.82 18.75 116.82 22.39L116.82 32C116.82 33.27 117.24 33.91 118.08 33.91C118.29 33.91 118.56 33.88 118.88 33.86ZM109.49 29.1L109.49 27.83L104.17 27.83C103.12 27.83 102.27 27.94 101.64 28.16C101.01 28.38 100.51 28.65 100.15 28.98C99.79 29.31 99.55 29.67 99.42 30.05C99.29 30.44 99.23 30.79 99.23 31.12C99.23 31.49 99.29 31.84 99.42 32.19C99.54 32.54 99.75 32.85 100.04 33.12C100.33 33.39 100.72 33.62 101.21 33.8C101.7 33.98 102.31 34.07 103.03 34.07C104.01 34.07 104.89 33.93 105.67 33.66C106.45 33.39 107.13 33.01 107.71 32.54C108.29 32.07 108.73 31.53 109.04 30.93C109.35 30.33 109.5 29.72 109.5 29.1L109.49 29.1Z"
              />
              <path
                fill={color}
                d="M147.85 30.7C148.55 30.83 149 30.91 149.22 30.95C148.93 32.19 148.44 33.36 147.77 34.47C147.1 35.58 146.24 36.55 145.18 37.39C144.12 38.22 142.88 38.89 141.44 39.38C140 39.87 138.39 40.12 136.61 40.12C134.57 40.12 132.72 39.77 131.06 39.08C129.4 38.39 127.98 37.42 126.81 36.16C125.64 34.91 124.73 33.4 124.09 31.63C123.45 29.87 123.13 27.91 123.13 25.76C123.13 23.64 123.45 21.71 124.09 19.94C124.73 18.17 125.64 16.65 126.81 15.38C127.98 14.1 129.4 13.12 131.06 12.43C132.72 11.74 134.57 11.39 136.61 11.39C138.39 11.39 139.98 11.63 141.36 12.1C142.74 12.58 143.95 13.23 144.99 14.07C146.03 14.91 146.89 15.89 147.56 17.02C148.23 18.14 148.73 19.35 149.06 20.62C148.8 20.69 148.33 20.79 147.64 20.92C146.95 21.05 146.2 21.18 145.4 21.33C144.6 21.48 143.85 21.61 143.16 21.74C142.47 21.87 141.99 21.95 141.74 21.99C141.34 20.39 140.65 19.24 139.69 18.55C138.73 17.86 137.72 17.51 136.66 17.51C134.58 17.51 133.05 18.27 132.07 19.78C131.09 21.29 130.6 23.28 130.6 25.76C130.6 28.28 131.09 30.29 132.07 31.8C133.06 33.31 134.58 34.07 136.66 34.07C137.9 34.07 138.99 33.71 139.94 32.98C140.89 32.25 141.56 31.12 141.96 29.59C142.21 29.63 142.69 29.71 143.38 29.84C144.07 29.97 144.82 30.12 145.62 30.28C146.42 30.44 147.17 30.59 147.85 30.7Z"
              />
              <path
                fill={color}
                d="M154.58 1.14L162.44 1.14L162.44 9.06L154.58 9.06L154.58 1.14Z"
              />
              <path
                fill={color}
                d="M154.85 11.95L162.17 11.95L162.17 39.58L154.85 39.58L154.85 11.95Z"
              />
              <path
                fill={color}
                d="M179.73 4.25L179.73 11.96L185.19 11.96L185.19 17.26L179.73 17.26L179.73 31.73C179.73 32.53 179.86 33.12 180.11 33.5C180.36 33.88 180.85 34.07 181.58 34.07L184.37 33.85L184.37 39.58L180.16 39.91L179.45 39.91C176.9 39.91 175.08 39.23 173.99 37.86C172.9 36.5 172.35 34.61 172.35 32.21L172.35 17.25L167.82 17.25L167.82 11.95L172.35 11.95L172.35 4.25L179.72 4.25L179.73 4.25Z"
              />
              <path
                fill={color}
                d="M209.8 11.95L217.5 11.95L202.92 50.4L195.93 50.4L199.86 39.59L189.43 11.95L197.13 11.95L203.52 30.96L209.8 11.95Z"
              />
              <path
                fillRule="evenodd"
                fill={color}
                d="M261.77 8.41C262.35 9.88 262.64 11.46 262.64 13.13C262.64 14.84 262.35 16.44 261.77 17.91C261.18 19.39 260.37 20.65 259.31 21.71C258.25 22.77 256.99 23.6 255.51 24.2C254.03 24.8 252.4 25.1 250.62 25.1L241.61 25.1L241.61 39.63L233.91 39.63L233.91 1.18L250.62 1.18C252.41 1.18 254.04 1.49 255.51 2.11C256.99 2.73 258.25 3.56 259.31 4.62C260.36 5.68 261.18 6.94 261.77 8.41ZM254.94 13.12C254.94 12.35 254.8 11.62 254.53 10.92C254.26 10.23 253.91 9.63 253.47 9.14C253.03 8.65 252.53 8.25 251.97 7.96C251.41 7.67 250.82 7.52 250.2 7.52L241.63 7.52L241.63 18.77L250.2 18.77C250.81 18.77 251.4 18.62 251.97 18.33C252.53 18.03 253.03 17.63 253.47 17.12C253.9 16.61 254.26 16.01 254.53 15.31C254.8 14.61 254.94 13.88 254.94 13.12Z"
              />
              <path
                fill={color}
                d="M282.4 11.46C282.89 11.48 283.38 11.5 283.85 11.54L283.85 17.66L279.97 17.66C276.73 17.66 275.11 20.06 275.11 24.87L275.11 39.62L267.74 39.62L267.74 11.99L274.57 11.99L275.12 14.67C275.66 13.83 276.36 13.09 277.22 12.43C278.07 11.78 279.32 11.45 280.96 11.45C281.43 11.45 281.91 11.46 282.4 11.46Z"
              />
              <path
                fillRule="evenodd"
                fill={color}
                d="M312.73 19.99C313.35 21.76 313.66 23.7 313.66 25.81C313.66 27.95 313.35 29.9 312.73 31.65C312.11 33.39 311.24 34.91 310.11 36.18C308.98 37.46 307.6 38.44 305.96 39.13C304.32 39.82 302.5 40.17 300.5 40.17C298.43 40.17 296.58 39.82 294.96 39.13C293.34 38.44 291.96 37.45 290.81 36.18C289.66 34.91 288.79 33.4 288.19 31.65C287.59 29.91 287.29 27.96 287.29 25.81C287.29 23.69 287.59 21.76 288.19 19.99C288.79 18.22 289.66 16.7 290.81 15.43C291.96 14.15 293.34 13.17 294.96 12.48C296.58 11.79 298.43 11.44 300.5 11.44C302.5 11.44 304.32 11.8 305.96 12.51C307.6 13.22 308.98 14.2 310.11 15.46C311.23 16.72 312.11 18.23 312.73 19.99ZM306.5 25.81C306.5 23 305.94 20.93 304.81 19.58C303.69 18.23 302.25 17.56 300.5 17.56C298.68 17.56 297.21 18.23 296.1 19.58C294.98 20.93 294.43 23.01 294.43 25.81C294.43 28.65 294.99 30.74 296.1 32.09C297.21 33.44 298.68 34.11 300.5 34.11C302.24 34.11 303.68 33.44 304.81 32.09C305.94 30.75 306.5 28.65 306.5 25.81Z"
              />
              <path
                fill={color}
                d="M328.95 4.29L328.95 12L334.41 12L334.41 17.3L328.95 17.3L328.95 31.77C328.95 32.57 329.08 33.16 329.33 33.54C329.58 33.92 330.07 34.11 330.8 34.11L333.59 33.89L333.59 39.62L329.38 39.95L328.67 39.95C326.12 39.95 324.3 39.27 323.21 37.9C322.12 36.54 321.57 34.65 321.57 32.25L321.57 17.29L317.04 17.29L317.04 11.99L321.57 11.99L321.57 4.29L328.94 4.29L328.95 4.29Z"
              />
              <path
                fillRule="evenodd"
                fill={color}
                d="M363.27 19.99C363.89 21.76 364.2 23.7 364.2 25.81C364.2 27.95 363.89 29.9 363.27 31.65C362.65 33.39 361.78 34.91 360.65 36.18C359.52 37.46 358.14 38.44 356.5 39.13C354.86 39.82 353.04 40.17 351.04 40.17C348.97 40.17 347.12 39.82 345.5 39.13C343.88 38.44 342.5 37.45 341.35 36.18C340.2 34.91 339.33 33.4 338.73 31.65C338.13 29.91 337.83 27.96 337.83 25.81C337.83 23.69 338.13 21.76 338.73 19.99C339.33 18.22 340.2 16.7 341.35 15.43C342.5 14.15 343.88 13.17 345.5 12.48C347.12 11.79 348.97 11.44 351.04 11.44C353.04 11.44 354.86 11.8 356.5 12.51C358.14 13.22 359.52 14.2 360.65 15.46C361.78 16.72 362.66 18.23 363.27 19.99ZM357.05 25.81C357.05 23 356.49 20.93 355.36 19.58C354.24 18.23 352.8 17.56 351.05 17.56C349.23 17.56 347.76 18.23 346.65 19.58C345.53 20.93 344.98 23.01 344.98 25.81C344.98 28.65 345.54 30.74 346.65 32.09C347.76 33.44 349.23 34.11 351.05 34.11C352.79 34.11 354.23 33.44 355.36 32.09C356.48 30.75 357.05 28.65 357.05 25.81Z"
              />
              <path
                fill={color}
                d="M393.49 30.74C394.19 30.87 394.64 30.95 394.86 30.99C394.57 32.23 394.08 33.4 393.41 34.51C392.74 35.62 391.88 36.59 390.82 37.43C389.76 38.26 388.52 38.93 387.08 39.42C385.64 39.91 384.03 40.16 382.25 40.16C380.21 40.16 378.36 39.81 376.7 39.12C375.04 38.43 373.62 37.46 372.45 36.2C371.28 34.95 370.37 33.44 369.73 31.67C369.09 29.91 368.77 27.95 368.77 25.8C368.77 23.68 369.09 21.75 369.73 19.98C370.37 18.21 371.28 16.69 372.45 15.42C373.62 14.14 375.04 13.16 376.7 12.47C378.36 11.78 380.21 11.43 382.25 11.43C384.03 11.43 385.62 11.67 387 12.14C388.38 12.62 389.59 13.27 390.63 14.11C391.67 14.95 392.53 15.93 393.2 17.06C393.87 18.18 394.37 19.39 394.7 20.66C394.44 20.73 393.97 20.83 393.28 20.96C392.59 21.09 391.84 21.22 391.04 21.37C390.24 21.52 389.49 21.65 388.8 21.78C388.11 21.91 387.63 21.99 387.38 22.03C386.98 20.43 386.29 19.28 385.33 18.59C384.37 17.9 383.36 17.55 382.3 17.55C380.22 17.55 378.69 18.31 377.71 19.82C376.73 21.33 376.24 23.32 376.24 25.8C376.24 28.32 376.73 30.33 377.71 31.84C378.7 33.35 380.22 34.11 382.3 34.11C383.54 34.11 384.63 33.75 385.58 33.02C386.53 32.29 387.2 31.16 387.6 29.63C387.85 29.67 388.33 29.75 389.02 29.88C389.71 30.01 390.46 30.16 391.26 30.32C392.06 30.48 392.81 30.63 393.49 30.74Z"
              />
              <path
                fillRule="evenodd"
                fill={color}
                d="M424.88 19.99C425.5 21.76 425.81 23.7 425.81 25.81C425.81 27.95 425.5 29.9 424.88 31.65C424.26 33.39 423.39 34.91 422.26 36.18C421.13 37.46 419.75 38.44 418.11 39.13C416.47 39.82 414.65 40.17 412.65 40.17C410.58 40.17 408.73 39.82 407.11 39.13C405.49 38.44 404.11 37.45 402.96 36.18C401.81 34.91 400.94 33.4 400.34 31.65C399.74 29.91 399.44 27.96 399.44 25.81C399.44 23.69 399.74 21.76 400.34 19.99C400.94 18.22 401.81 16.7 402.96 15.43C404.11 14.15 405.49 13.17 407.11 12.48C408.73 11.79 410.58 11.44 412.65 11.44C414.65 11.44 416.47 11.8 418.11 12.51C419.75 13.22 421.13 14.2 422.26 15.46C423.38 16.72 424.26 18.23 424.88 19.99ZM418.65 25.81C418.65 23 418.09 20.93 416.96 19.58C415.84 18.23 414.4 17.56 412.65 17.56C410.83 17.56 409.36 18.23 408.25 19.58C407.13 20.93 406.58 23.01 406.58 25.81C406.58 28.65 407.14 30.74 408.25 32.09C409.36 33.44 410.83 34.11 412.65 34.11C414.39 34.11 415.83 33.44 416.96 32.09C418.09 30.75 418.65 28.65 418.65 25.81Z"
              />

              <path
                fill={color}
                d="M430.91 1.18L438.28 1.18L438.28 39.63L430.91 39.63L430.91 1.18Z"
              />
            </>
          )}
          <path fill={color} d="M18.59 1.14L32.66 25.51L24.21 40.14L1.7 1.14L18.59 1.14Z" />
          <path fill={color} d="M42.33 8.76L39.69 13.33L32.66 1.14L37.93 1.14L42.33 8.76Z" />
          <path fill={color} d="M44.53 4.95L42.33 1.14L43.98 1.14L45.35 3.52L44.53 4.95Z" />
          <path fill={color} d="M46.04 2.33L45.35 1.14L45.87 1.14L46.3 1.89L46.04 2.33Z" />
          <path fill={color} d="M46.51 1.51L46.3 1.14L46.46 1.14L46.59 1.37L46.51 1.51Z" />
          <path fill={color} d="M46.66 1.26L46.59 1.14L46.64 1.14L46.69 1.21L46.66 1.26Z" />
          <path fill={color} d="M46.72 1.15L46.71 1.14L46.73 1.14L46.72 1.15Z" />
          <path fill={color} d="M46.71 1.18L46.69 1.14L46.7 1.14L46.71 1.16L46.71 1.18Z" />
        </g>
      </g>
    </svg>
  );
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark',
  size: 'default',
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['default', 'large']),
};
