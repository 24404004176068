import { createSvgIcon } from '@material-ui/core/utils';

export const ShieldFailOutlined = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3999 38.2002C19.3999 38.2002 33.3999 31.2002 33.3999 20.7002V8.4502L19.3999 3.2002L5.3999 8.4502V20.7002C5.3999 31.2002 19.3999 38.2002 19.3999 38.2002Z"
      stroke="#CC1F2B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24 14L14 24" stroke="#CC1F2B" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M14 14L24 24" stroke="#CC1F2B" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>,
  'ShieldFailOutlined',
);
