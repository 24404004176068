import { Box, Chip, CircularProgress, Typography } from '@material-ui/core';
import { FC, useCallback } from 'react';
import PropTypes from 'prop-types';
import { EJobResultCode } from '../../../../types/job';

export enum EItemFace {
  Front = 'Front',
  Back = 'Back',
}

interface ImageItemProps {
  face: EItemFace;
  active: boolean;
  isLoading: boolean;
  streamUrl?: string;
  imageBase64?: string;
  resultCode?: EJobResultCode;
}

const getResultColor = (code?: EJobResultCode) => {
  if (code === EJobResultCode.OK) {
    return '#00FF00';
  }

  if (!code) {
    return '#ffffff';
  }

  return '#FF0000';
};

export const ImageItem: FC<ImageItemProps> = ({
  face,
  active,
  isLoading,
  streamUrl,
  imageBase64,
  resultCode,
}) => {
  const getContent = useCallback(() => {
    if (imageBase64) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            img: {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            },
          }}
        >
          <img src={`data:image/jpeg;base64,${imageBase64}`} alt={`Result ${face}`} />
        </Box>
      );
    }

    if (isLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gridGap: '1em',
            margin: 'auto',
            zIndex: 2,
          }}
        >
          <CircularProgress
            color="inherit"
            size={100}
            thickness={5}
            sx={{
              color: '#ffffff !important',
              p: 1,
            }}
          />
        </Box>
      );
    }

    if (active) {
      return <></>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gridGap: '1em',
          margin: 'auto',
          zIndex: 2,
        }}
      >
        <Typography variant="h5">
          {face === EItemFace.Front ? 'Front Side' : 'Back Side'}
        </Typography>
      </Box>
    );
  }, [face, active, isLoading, imageBase64]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        p: 2,
        opacity: active || imageBase64 ? 1 : 0.5,
        ':not(:last-child)': {
          borderRight: '1px solid #FFFFFF',
        },
        position: 'relative',
      }}
    >
      {/* Side title + Result */}
      {(imageBase64 || isLoading) && (
        <Chip
          label={face === EItemFace.Front ? 'Front' : 'Back'}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            m: 2,
            background: getResultColor(resultCode),
            zIndex: 2,
            boxShadow: '0px 0px 5px 1px black',
          }}
        />
      )}
      {/* Camera stream */}
      <Box
        sx={{
          opacity: isLoading ? 0.5 : 1,
          position: 'absolute',
          display: active && !imageBase64 ? 'block' : 'none',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          overflow: 'hidden',
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
        }}
      >
        <img src={active && !imageBase64 ? streamUrl : '#'} alt="Camera Stream" />
      </Box>

      {/* Content */}
      {getContent()}
    </Box>
  );
};

ImageItem.propTypes = {
  face: PropTypes.any.isRequired,
  active: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  streamUrl: PropTypes.string,
  imageBase64: PropTypes.string,
  resultCode: PropTypes.number,
};
