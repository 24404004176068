import { createSvgIcon } from '@material-ui/core/utils';

export const CustomCube = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 16.9996C11.0001 17.1699 11.0437 17.3375 11.1267 17.4863C11.2098 17.6351 11.3294 17.7602 11.4744 17.8497C11.6194 17.9393 11.7848 17.9903 11.955 17.998C12.1252 18.0057 12.2946 17.9697 12.447 17.8935L16.447 15.8935C16.6131 15.8106 16.7528 15.683 16.8504 15.5251C16.9481 15.3672 16.9999 15.1852 17 14.9996V9.23555C16.9999 9.06516 16.9563 8.89762 16.8733 8.74882C16.7902 8.60003 16.6706 8.47491 16.5256 8.38536C16.3806 8.2958 16.2152 8.24476 16.045 8.2371C15.8748 8.22943 15.7054 8.26539 15.553 8.34155L11.553 10.3415C11.3869 10.4245 11.2472 10.5521 11.1496 10.71C11.0519 10.8679 11.0001 11.0499 11 11.2355V16.9996ZM4.447 8.34155C4.29458 8.26539 4.12522 8.22943 3.95501 8.2371C3.78479 8.24476 3.61935 8.2958 3.47439 8.38536C3.32944 8.47491 3.20977 8.60003 3.12674 8.74882C3.04372 8.89762 3.00009 9.06516 3 9.23555V14.9996C3.0001 15.1852 3.05188 15.3672 3.14955 15.5251C3.24722 15.683 3.38692 15.8106 3.553 15.8935L7.553 17.8935C7.70542 17.9697 7.87477 18.0057 8.04499 17.998C8.21521 17.9903 8.38065 17.9393 8.52561 17.8497C8.67056 17.7602 8.79023 17.6351 8.87326 17.4863C8.95628 17.3375 8.99991 17.1699 9 16.9996V11.2355C8.9999 11.0499 8.94812 10.8679 8.85045 10.71C8.75278 10.5521 8.61308 10.4245 8.447 10.3415L4.447 8.34155Z"
    />
    <path
      opacity="0.5"
      d="M15.2112 6.27547C15.3771 6.19233 15.5166 6.06468 15.6141 5.90679C15.7115 5.74891 15.7632 5.56702 15.7632 5.38147C15.7632 5.19592 15.7115 5.01403 15.6141 4.85614C15.5166 4.69826 15.3771 4.5706 15.2112 4.48747L10.4472 2.10547C10.3084 2.03611 10.1554 2 10.0002 2C9.84507 2 9.69204 2.03611 9.55324 2.10547L4.78924 4.48747C4.62336 4.5706 4.48388 4.69826 4.3864 4.85614C4.28893 5.01403 4.2373 5.19592 4.2373 5.38147C4.2373 5.56702 4.28893 5.74891 4.3864 5.90679C4.48388 6.06468 4.62336 6.19233 4.78924 6.27547L9.55324 8.65747C9.69204 8.72682 9.84507 8.76293 10.0002 8.76293C10.1554 8.76293 10.3084 8.72682 10.4472 8.65747L15.2112 6.27547Z"
    />
  </svg>,
  'CustomCube'
);
