import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { FC, useCallback, useState } from 'react';
import { InputField, InputFieldProps } from './input-field';

type PasswordFieldProps = InputFieldProps;

export const PasswordField: FC<PasswordFieldProps> = (props) => {
  const [show, setShow] = useState(false);

  const handleShowClick = useCallback(() => {
    setShow((prev) => !prev);
  }, []);

  const handleShowMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  }, []);

  return (
    <InputField
      {...props}
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ pr: 2 }}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowClick}
              onMouseDown={handleShowMouseDown}
              edge="end"
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
