import type { FC, ReactNode, ElementType } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import type { ListItemProps } from '@material-ui/core';

interface PropertyListItemProps extends ListItemProps {
  align?: string;
  children?: ReactNode;
  component?: ElementType;
  label: string;
  value?: string;
  labelColor?: string;
  color?: string;
}

export const PropertyListItem: FC<PropertyListItemProps> = (props) => {
  const { align, children, component, value, label, labelColor, color, ...other } = props;

  return (
    <ListItem
      component={component}
      disableGutters
      sx={{
        px: 3,
        py: 1.5,
      }}
      {...other}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography
            color={labelColor || 'textPrimary'}
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 190, pr: 1 }}
            variant="subtitle2"
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
            }}
          >
            {children || (
              <Typography
                color={color || 'textSecondary'}
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                title={value}
              >
                {value}
              </Typography>
            )}
          </Box>
        }
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
        }}
      />
    </ListItem>
  );
};

PropertyListItem.defaultProps = {
  align: 'vertical',
};

PropertyListItem.propTypes = {
  align: PropTypes.string,
  // @ts-ignore
  component: PropTypes.elementType,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
};
