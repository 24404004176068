import { FC, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useSettings } from '../contexts/settings-context';
import { InputField } from './input-field';
import { Plus as PlusIcon } from '../icons/plus';
import { Trash as TrashIcon } from '../icons/trash';

const getNewCamera = () => ({
  id: `camera-${Math.floor(Math.random() * 100)}`,
  title: '',
  url: '',
});

const DEFAULT_CAMERAS = [];

interface CameraSettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CameraSettingsDialog: FC<CameraSettingsDialogProps> = ({
  open,
  onClose,
  ...other
}) => {
  const { settings, saveSettings } = useSettings();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cameras: settings.cameras ?? DEFAULT_CAMERAS,
      submit: null,
    },
    validationSchema: Yup.object().shape({
      cameras: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          title: Yup.string().optional(),
          url: Yup.string().required('URL is required'),
        }),
      ),
    }),
    onSubmit: async (values, helpers) => {
      const { submit, ...vals } = values;

      const newCameras = vals.cameras
        .filter((val) => val.url)
        .map((val, i, arr) => ({
          id: val.id,
          title: val.title || `Camera${arr.length > 1 ? ` ${i + 1}` : ''}`,
          url: val.url,
        }));

      const defaultCaptureMethod = {
        ...settings.defaultCaptureMethod,
      };

      if (!newCameras.find((camera) => camera.id === defaultCaptureMethod.protection)) {
        defaultCaptureMethod.protection = undefined;
      }

      if (!newCameras.find((camera) => camera.id === defaultCaptureMethod.verification)) {
        defaultCaptureMethod.verification = undefined;
      }

      saveSettings({
        ...settings,
        cameras: newCameras,
        defaultCaptureMethod,
      });

      helpers.setSubmitting(false);

      toast.success('Settings saved');

      helpers.resetForm();
      onClose();
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [formik, onClose]);

  const getItemError = (index: number, property: string): string | undefined =>
    formik?.touched?.cameras &&
    formik?.errors?.cameras &&
    formik?.touched?.cameras[index]?.[property] &&
    formik?.errors?.cameras[index]?.[property];

  return (
    <Dialog onClose={handleClose} open={open} fullWidth {...other}>
      <DialogTitle>Camera Settings</DialogTitle>
      <DialogContent>
        <Box>
          {/* Cameras */}
          {formik.values.cameras?.map((camera, index) => (
            <Box
              key={camera.id}
              sx={{
                display: 'flex',
                alignItems: 'start',
                mt: 2,
                gridGap: '0.5rem',
              }}
            >
              <Typography variant="subtitle1" mt={4} mr={1}>
                {index + 1}.
              </Typography>
              {/* Camera ID */}
              <input name={`cameras[${index}].id`} type="hidden" value={camera.id} />
              {/* Camera Title */}
              <InputField
                error={Boolean(getItemError(index, 'title'))}
                helperText={getItemError(index, 'title')}
                name={`cameras[${index}].title`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={camera.title}
                label="Title"
                placeholder={`Camera ${index + 1}`}
                sx={{
                  width: 200,
                }}
              />
              {/* Camera URL */}
              <InputField
                error={Boolean(getItemError(index, 'url'))}
                helperText={getItemError(index, 'url')}
                name={`cameras[${index}].url`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={camera.url}
                label="URL"
                placeholder="http://"
                sx={{
                  width: 400,
                }}
              />
              {/* Delete Camera Button */}
              <IconButton
                onClick={() => {
                  const newVerticals = [...formik.values.cameras];
                  newVerticals.splice(index, 1);
                  formik.setFieldValue(
                    'cameras',
                    newVerticals.length ? newVerticals : DEFAULT_CAMERAS,
                  );
                }}
                sx={{
                  mt: 3,
                }}
              >
                <TrashIcon />
              </IconButton>
            </Box>
          ))}
          {/* Add Camera Button */}
          <Button
            color="primary"
            onClick={() => {
              formik.setFieldValue('cameras', [...formik.values.cameras, getNewCamera()]);
            }}
            startIcon={<PlusIcon />}
            sx={{ mt: 2 }}
            variant="text"
          >
            Add Camera
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CameraSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
