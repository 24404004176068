import { EAssetTitle } from '../types/asset';
import { Item } from '../types/item';
import { Task } from '../types/job';
import { SKU } from '../types/sku';

export const getAssetUrl = (
  objectWithAssets: Item | Task | SKU,
  assetTitle: EAssetTitle | EAssetTitle[],
) =>
  Array.isArray(assetTitle)
    ? assetTitle.reduce(
        (prev, curr) => prev ?? objectWithAssets?.assets?.find((a) => a.title === curr)?.file?.url,
        undefined as string,
      )
    : objectWithAssets?.assets?.find((a) => a.title === assetTitle)?.file?.url;

export const getThumbnailUrl = (item: Item | SKU) =>
  [EAssetTitle.thumbnail, EAssetTitle.THUMBNAIL, EAssetTitle.FRONT_THUMBNAIL].reduce(
    (url, asset) => url ?? getAssetUrl(item, asset),
    undefined,
  );

export const getOverviewUrl = (item: Item | SKU) =>
  [EAssetTitle.overview, EAssetTitle.OVERVIEW, EAssetTitle.FRONT_PREVIEW].reduce(
    (url, asset) => url ?? getAssetUrl(item, asset),
    undefined,
  );
