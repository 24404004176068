import { FC, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { InfoContainer, InfoType } from '../components/flow/info-container';
import { ActionsContainer, ActionsType } from '../components/flow/actions-container';
import { useScanner } from '../hooks/use-scanner';
import { ScanMessage, MessageAction, ESocketEvent } from '../contexts/scanner-context';
import { useAxios } from '../../../hooks/use-axios';
import {
  Job,
  EJobResultCode,
  EJobStatus,
  EJobTitle,
  CreateJobData,
  CreateTaskData,
  Task,
} from '../../../types/job';
import { RemoteFile } from '../../../types/remote-file';
import { ListResponse, ResponseData } from '../../../types/axios';
import { useAuth } from '../../../hooks/use-auth';
import { EItemFace } from '../components/flow/image-item';
import { ImageContianer } from '../components/flow/image-container';
import logger, { permaLogger } from '../../../utils/logger';
import { JobErrorDialog } from '../components/flow/job-error-dialog';
import { useDialog } from '../../../hooks/use-dialog';
import { Item } from '../../../types/item';
import { EFingerpintTitle } from '../../../types/fingerprint';
import { ImageFile } from '../types/image-file';
import { LoadingDialog } from '../components/loading-dialog';
import { E_RUNNING_ERROR, RETRY_COUNT, RETRY_INTERVAL } from '../utils/flow-helper';

export enum VerificationStep {
  Front = 'front',
  Back = 'back',
  Analyzing = 'analysing',
  Verified = 'verified',
  NotVerified = 'notverified',
}

interface QueryImages {
  frontImage: ImageFile;
  backImage: ImageFile;
}

interface FlowVerificationProps {
  step: VerificationStep;
}

export const FlowVerification: FC<FlowVerificationProps> = ({ step }) => {
  const [queryImages, setQueryImages] = useState<QueryImages>();
  const [verificationId, setVerificationId] = useState<string>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [jobError, setJobError] = useState<boolean>(false);
  const [cancelVerification, setCancelVerification] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());
  const [jobErrorOpen, handleJobErrorOpen, handleJobErrorClose] = useDialog();
  const { item, socket, captureImage, loadItem, isItemLoading, abortImageCapture } = useScanner();
  const { axios } = useAxios();
  const { tenant } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const abortHttpRequests = useCallback(() => {
    abortImageCapture();

    abortController.abort();
    setAbortController(new AbortController());
  }, [abortImageCapture, abortController]);

  const handleMutationError = useCallback((error: any) => {
    logger('[verification/mutation] Error', error);
    if (error?.message !== 'canceled') {
      toast.error('Something went wrong while verifying item');
      setIsImageLoading(false);
      setJobError(true);
    }
  }, []);

  const handleCaptureError = useCallback((error: any) => {
    logger('[verification/capture] Error', error);
    if (error?.message !== 'canceled') {
      toast.error('Something went wrong while capturing image');
      setIsImageLoading(false);
      setJobError(true);
    }
  }, []);

  const startJobMutation = useMutation(
    async (newJobId: string) =>
      axios.post<ResponseData<Job>>(`jobs/${newJobId}/start`, undefined, {
        signal: abortController.signal,
      }),
    {
      onMutate: () => {
        permaLogger('[verification/start_job] Starting job');
      },
      onSuccess: () => {
        permaLogger('[verification/start_job] Job started');
      },
      onError: handleMutationError,
    },
  );

  const { data: job } = useQuery(
    ['job', verificationId, startJobMutation.status],
    async () => {
      if (verificationId && startJobMutation.isSuccess) {
        permaLogger('[verification/job] Fetching job');
        const {
          data: { data: result },
        } = await axios.get<ResponseData<Job>>(`/jobs/${verificationId}`);

        if (result?.status !== EJobStatus.DONE) {
          throw Error(E_RUNNING_ERROR);
        }

        permaLogger('[verification/job] Job completed');
        return result;
      }

      return undefined;
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      retryDelay: RETRY_INTERVAL,
      retry: RETRY_COUNT,
      onError: (error: any) => {
        if (error?.message === E_RUNNING_ERROR) {
          logger('[verification/job] Job timeout');
          handleMutationError(error);
        }
      },
    },
  );

  const { data: tasks } = useQuery(['tasks', job], async () => {
    if (job?.id) {
      const {
        data: {
          data: { data: result },
        },
      } = await axios.get<ResponseData<ListResponse<Task>>>(`/jobs/${job.id}/tasks`);

      return result;
    }

    return undefined;
  });

  const getResultCode = useCallback(
    (side: VerificationStep) => {
      if (tasks) {
        const fingerprintTitle =
          side === VerificationStep.Front
            ? EFingerpintTitle.PROTECTION_FRONT
            : EFingerpintTitle.PROTECTION_BACK;

        const task = tasks.find((t) => t.publicMetadata?.fingerprintTitle === fingerprintTitle);
        if (task) {
          return task.resultCode;
        }
      }

      return undefined;
    },
    [tasks],
  );

  const createJobMutation = useMutation(
    async () => {
      const data: CreateJobData = {
        title: EJobTitle.VERIFICATION,
        tenantId: tenant?.id,
        itemId: (item as Item)?.id,
      };

      return axios.post<ResponseData<Job>>('/jobs', data, {
        signal: abortController.signal,
      });
    },
    {
      onMutate: () => {
        permaLogger('[verification/job] Creating job');
        setVerificationId(undefined);
      },
      onSuccess: ({ data: { data } }) => {
        permaLogger('[verification/job] Job created');
        setVerificationId(data.id);
      },
      onError: handleMutationError,
    },
  );

  const updateImageMutation = useMutation(async (image: RemoteFile) => {
    const data = {
      uploadTime: image.uploadTime,
    };

    return axios.put(`/files/${image.id}`, data, {
      signal: abortController.signal,
    });
  });

  const createTaskMutation = useMutation(
    async ({
      newJobId,
      image,
      itemSide,
    }: {
      newJobId: string;
      image: RemoteFile;
      itemSide: VerificationStep;
    }) => {
      if (Number.isFinite(image.uploadTime)) {
        updateImageMutation.mutateAsync(image);
      }

      const data: CreateTaskData = {
        fileId: image.id,
        publicMetadata: {
          fingerprintTitle:
            itemSide === VerificationStep.Front
              ? EFingerpintTitle.PROTECTION_FRONT
              : EFingerpintTitle.PROTECTION_BACK,
        },
      };

      if (process.env.REACT_APP_DEBUG_MODE && item.debug) {
        data.debug = item.debug;
      }

      return axios.post<ResponseData<Task>>(
        `jobs/${newJobId}/tasks/authentication/verification`,
        data,
        {
          signal: abortController.signal,
        },
      );
    },
    {
      onMutate: ({ itemSide }) => {
        permaLogger('[verification/create_task] Creating task', itemSide);
      },
      onSuccess: ({ data: { data: newTask } }) => {
        permaLogger(
          '[verification/create_task] Task created',
          newTask.publicMetadata?.fingerprintTitle,
        );
      },
      onError: handleMutationError,
    },
  );

  const verifyFrontMutation = useMutation(async (image: RemoteFile) =>
    createTaskMutation.mutateAsync({
      newJobId: verificationId,
      image,
      itemSide: VerificationStep.Front,
    }),
  );

  const verifyBackMutation = useMutation(async (image: RemoteFile) =>
    createTaskMutation.mutateAsync({
      newJobId: verificationId,
      image,
      itemSide: VerificationStep.Back,
    }),
  );

  const cancelVerificationMutation = useMutation(
    async () => {
      try {
        if (verificationId) {
          const urlJob = `jobs/${verificationId}`;
          await axios.delete(urlJob);
        }
      } catch (error) {
        if (error?.response?.status !== 409) {
          throw error;
        }
      }
    },
    {
      onSuccess: () => {
        permaLogger('[verification/cancel] Verification canceled');
      },
      onError: (error: any) => {
        logger('[verification/cancel] Error', error?.response?.message || error);
      },
    },
  );

  const restartView = useCallback(() => {
    setVerificationId(undefined);
    setQueryImages(undefined);
    setIsImageLoading(false);
    setJobError(false);
    setCancelVerification(false);
    setAbortController(new AbortController());

    createJobMutation.reset();
    startJobMutation.reset();
    verifyFrontMutation.reset();
    verifyBackMutation.reset();
    cancelVerificationMutation.reset();

    queryClient.invalidateQueries('job');
  }, []);

  const restartFlow = useCallback(async () => {
    const afterReset = (failed?: boolean) => {
      restartView();

      handleJobErrorClose();
      if (failed) {
        toast.error('Something went wrong while canceling verification');
      } else {
        toast.success('Verification canceled');
      }
      navigate('/');
    };

    // Abort http requests and other image capture
    abortHttpRequests();

    // Cancel if already protecting
    if (verificationId) {
      cancelVerificationMutation.mutate(undefined, {
        onSuccess: () => {
          afterReset();
        },
        onError: () => {
          afterReset(true);
        },
      });
    } else {
      afterReset();
    }
  }, [verificationId, abortHttpRequests, restartView]);

  const handleClose = useCallback(() => {
    // Canceled verification
    if (step !== VerificationStep.Verified && step !== VerificationStep.NotVerified) {
      setCancelVerification(true);
    } else {
      navigate('/');
    }
  }, [step, job]);

  const captureImageHandler = useCallback(
    (side: VerificationStep) => {
      const m = item.sku.publicMetadata;
      // SKU Size
      const { width: skuWidth, height: skuHeight, depth: skuDepth, isInSleeve } = m;
      // SKU Front Offset
      let {
        offsetFrontTop: offsetTop,
        offsetFrontRight: offsetRight,
        offsetFrontBottom: offsetBottom,
        offsetFrontLeft: offsetLeft,
      } = m;
      // SKU Back Offset
      if (side === VerificationStep.Back) {
        offsetTop = m.offsetBackTop;
        offsetRight = m.offsetBackRight;
        offsetBottom = m.offsetBackBottom;
        offsetLeft = m.offsetBackLeft;
      }

      captureImage(
        {
          title: item.title,
          skuWidth,
          skuHeight,
          skuDepth,
          offsetTop,
          offsetRight,
          offsetBottom,
          offsetLeft,
          isInSleeve: isInSleeve?.toString() !== 'false', // Default to true
          isHDR: false,
          side,
        },
        (data, error) => {
          if (!data) {
            handleCaptureError(error);
            return;
          }

          permaLogger(
            '[verification/capture] Image captured',
            data.isHDR ? 'HDR' : 'FP',
            data.side,
          );

          if (data.side === VerificationStep.Front) {
            setQueryImages((prev) => ({
              ...prev,
              frontImage: { remoteFile: data.image, base64: data.previewBase64 },
            }));
          } else {
            setQueryImages((prev) => ({
              ...prev,
              backImage: { remoteFile: data.image, base64: data.previewBase64 },
            }));
          }
        },
      );
    },
    [captureImage, item],
  );

  const captureFrontImage = useCallback(() => {
    permaLogger('[verification/capture] Capturing front image');
    setIsImageLoading(true);
    // Capture Query Image
    captureImageHandler(VerificationStep.Front);
    // Create Job to save time
    createJobMutation.mutate();
  }, [captureImageHandler]);

  const captureBackImage = useCallback(() => {
    permaLogger('[verification/capture] Capturing back image');
    setIsImageLoading(true);
    // Capture Query Image
    captureImageHandler(VerificationStep.Back);
  }, [captureImageHandler]);

  const messageHandler = useCallback(
    ({ action, data }: ScanMessage) => {
      if (action !== MessageAction.ItemScan) {
        return;
      }

      // Ignore item scan if error occured
      if (jobError) {
        return;
      }

      permaLogger('[verification/scan] Item scanned');

      // Scaning of item on result screen will load new item
      if (step === VerificationStep.Verified || step === VerificationStep.NotVerified) {
        if (!isItemLoading) {
          restartView();
          loadItem(data);
        }
        return;
      }

      // Scanning same item will take a photo
      if (
        !isImageLoading &&
        data.uniqueId === item.publicMetadata?.uniqueId &&
        step !== VerificationStep.Analyzing
      ) {
        if (step === VerificationStep.Front) {
          captureFrontImage();
        } else {
          captureBackImage();
        }
      }
    },
    [jobError, step, captureImageHandler, isImageLoading],
  );

  const cameraAvailableMessageHandler = useCallback(
    (data: { side: VerificationStep }) => {
      permaLogger('[verification/camera] Camera available', data.side);

      setIsImageLoading(false);

      if (data.side === VerificationStep.Front && step !== VerificationStep.Front) {
        return;
      }

      if (data.side === VerificationStep.Back && step !== VerificationStep.Back) {
        return;
      }

      navigate(
        data.side === VerificationStep.Front ? '/verification/back' : '/verification/analyzing',
        {
          replace: true,
        },
      );
    },
    [step],
  );

  // Verify back after FP image was captured and job is created
  useEffect(() => {
    if (jobError) {
      return;
    }

    if (verificationId) {
      if (queryImages?.frontImage?.remoteFile && verifyFrontMutation.isIdle) {
        verifyFrontMutation.mutate(queryImages.frontImage.remoteFile);
      }

      if (queryImages?.backImage?.remoteFile && verifyBackMutation.isIdle) {
        verifyBackMutation.mutate(queryImages.backImage.remoteFile);
      }

      if (
        startJobMutation.isIdle &&
        verifyFrontMutation.isSuccess &&
        verifyBackMutation.isSuccess
      ) {
        startJobMutation.mutate(verificationId);
      }
    }
  }, [
    jobError,
    queryImages,
    verificationId,
    verifyFrontMutation.status,
    verifyBackMutation.status,
    startJobMutation.status,
  ]);

  useEffect(() => {
    if (jobError) {
      abortHttpRequests();
      handleJobErrorOpen();
    } else {
      handleJobErrorClose();
    }
  }, [jobError]);

  useEffect(() => {
    restartView();
  }, [item, restartView]);

  useEffect(() => {
    if (jobError) {
      return;
    }

    if (step === VerificationStep.Analyzing && job?.status === EJobStatus.DONE) {
      permaLogger('[verification/result] Everything done, going to result screen');
      switch (job.resultCode) {
        case EJobResultCode.OK:
          navigate('/verification/success', { replace: true });
          break;
        default:
          navigate('/verification/fail', { replace: true });
          break;
      }
    }
  }, [jobError, step, job]);

  useEffect(() => {
    socket?.on(ESocketEvent.ScanEvent, messageHandler);
    socket?.on(ESocketEvent.CameraAvailableEvent, cameraAvailableMessageHandler);

    return () => {
      socket?.off(ESocketEvent.ScanEvent, messageHandler);
      socket?.off(ESocketEvent.CameraAvailableEvent, cameraAvailableMessageHandler);
    };
  }, [socket, messageHandler]);

  // Canceling process
  useEffect(() => {
    if (cancelVerification) {
      setCancelVerification(false);

      if (createJobMutation.isIdle && !verificationId && !isImageLoading) {
        navigate('/');
        return;
      }

      restartFlow();
    }
  }, [cancelVerification, createJobMutation.isIdle, verificationId, restartFlow]);

  if (!item) {
    return <Navigate to="/" replace />;
  }

  const getContent = () => {
    switch (step) {
      case VerificationStep.Front:
        return (
          <>
            <ImageContianer
              face={EItemFace.Front}
              isImageLoading={isImageLoading}
              frontImageBase64={queryImages?.frontImage?.base64}
            />
            <InfoContainer
              type={InfoType.Verification}
              item={item}
              showClose
              onClose={handleClose}
            />
            <ActionsContainer
              type={ActionsType.CaptureFront}
              onClick={captureFrontImage}
              isLoading={isImageLoading}
            />
          </>
        );

      case VerificationStep.Back:
        return (
          <>
            <ImageContianer
              face={EItemFace.Back}
              isImageLoading={isImageLoading}
              frontImageBase64={queryImages?.frontImage?.base64}
              backImageBase64={queryImages?.backImage?.base64}
            />
            <InfoContainer
              type={InfoType.Verification}
              item={item}
              showClose
              onClose={handleClose}
            />
            <ActionsContainer
              type={ActionsType.CaptureBack}
              onClick={captureBackImage}
              isLoading={isImageLoading}
            />
          </>
        );

      case VerificationStep.Analyzing:
        return (
          <>
            <ImageContianer
              frontImageBase64={queryImages?.frontImage?.base64}
              backImageBase64={queryImages?.backImage?.base64}
            />
            <InfoContainer
              type={InfoType.Verification}
              item={item}
              showClose
              onClose={handleClose}
            />
            <ActionsContainer type={ActionsType.Analyzing} />
          </>
        );

      case VerificationStep.Verified:
        return (
          <>
            <ImageContianer
              frontResultCode={getResultCode(VerificationStep.Front)}
              backResultCode={getResultCode(VerificationStep.Back)}
              frontImageBase64={queryImages?.frontImage?.base64}
              backImageBase64={queryImages?.backImage?.base64}
            />
            <InfoContainer type={InfoType.Verification} item={item} />
            <ActionsContainer type={ActionsType.Verified} onClick={handleClose} />
          </>
        );

      case VerificationStep.NotVerified:
        return (
          <>
            <ImageContianer
              frontResultCode={getResultCode(VerificationStep.Front)}
              backResultCode={getResultCode(VerificationStep.Back)}
              frontImageBase64={queryImages?.frontImage?.base64}
              backImageBase64={queryImages?.backImage?.base64}
            />
            <InfoContainer type={InfoType.Verification} item={item} />
            <ActionsContainer type={ActionsType.NotVerified} onClick={handleClose} />
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {getContent()}
      <JobErrorDialog open={jobErrorOpen} onClose={handleClose} />
      <LoadingDialog open={cancelVerificationMutation.isLoading} title="Canceling verification" />
    </>
  );
};

FlowVerification.propTypes = {
  step: PropTypes.any.isRequired,
};
