import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from '../../components/auth-guard';
import { GuestGuard } from '../../components/guest-guard';
import { LoadingScreen } from '../../components/loading-screen';
import { PermissionsGuard } from '../../components/permissions-guard';
import { TenantGuard } from '../../components/tenant-guard';
import { DashboardLayout } from '../../containers/dashboard-layout';
import { Permission } from '../../types/user';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Not found pages
const NotFound = Loadable(
  lazy(() =>
    import('../../containers/not-found').then((module) => ({
      default: module.NotFound,
    })),
  ),
);

// Auth pages
const Login = Loadable(
  lazy(() =>
    import('../../containers/login').then((module) => ({
      default: module.Login,
    })),
  ),
);

const Token = Loadable(
  lazy(() =>
    import('../../containers/token').then((module) => ({
      default: module.Token,
    })),
  ),
);

const ErrorPage = Loadable(
  lazy(() =>
    import('../../containers/error-page').then((module) => ({
      default: module.ErrorPage,
    })),
  ),
);

const NoPermissions = Loadable(
  lazy(() =>
    import('../../containers/no-permissions').then((module) => ({
      default: module.NoPermissions,
    })),
  ),
);

// Dashboard pages
const Items = Loadable(
  lazy(() =>
    import('../../containers/items').then((module) => ({
      default: module.Items,
    })),
  ),
);

const Item = Loadable(
  lazy(() =>
    import('../../containers/item').then((module) => ({
      default: module.Item,
    })),
  ),
);

const ItemDetails = Loadable(
  lazy(() =>
    import('../../containers/item-details').then((module) => ({
      default: module.ItemDetails,
    })),
  ),
);

const SKUs = Loadable(
  lazy(() =>
    import('../../containers/skus').then((module) => ({
      default: module.SKUs,
    })),
  ),
);

const SKUCreate = Loadable(
  lazy(() =>
    import('../../containers/sku-create').then((module) => ({
      default: module.SKUCreate,
    })),
  ),
);

const SKU = Loadable(
  lazy(() =>
    import('../../containers/sku').then((module) => ({
      default: module.SKU,
    })),
  ),
);

const ItemTimeline = Loadable(
  lazy(() =>
    import('../../containers/item-timeline').then((module) => ({
      default: module.ItemTimeline,
    })),
  ),
);

const Users = Loadable(
  lazy(() =>
    import('../../containers/users').then((module) => ({
      default: module.Users,
    })),
  ),
);

const UserCreate = Loadable(
  lazy(() =>
    import('../../containers/user-create').then((module) => ({
      default: module.UserCreate,
    })),
  ),
);

const UserAdd = Loadable(
  lazy(() =>
    import('../../containers/user-add').then((module) => ({
      default: module.UserAdd,
    })),
  ),
);

const UserEdit = Loadable(
  lazy(() =>
    import('../../containers/user-edit').then((module) => ({
      default: module.UserEdit,
    })),
  ),
);

const Jobs = Loadable(
  lazy(() =>
    import('../../containers/jobs').then((module) => ({
      default: module.Jobs,
    })),
  ),
);

const routes: PartialRouteObject[] = [
  {
    path: 'token',
    element: (
      <GuestGuard>
        <Token />
      </GuestGuard>
    ),
  },
  {
    path: '500',
    element: (
      <GuestGuard>
        <ErrorPage />
      </GuestGuard>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'no-permissions',
    element: (
      <AuthGuard>
        <NoPermissions />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'tenants',
        children: [
          {
            path: ':tenantId',
            element: <TenantGuard />,
            children: [
              {
                path: '/',
                element: <Navigate to="events" replace />,
              },
              {
                path: 'events',
                element: (
                  <PermissionsGuard permission={Permission.TENANT_ITEMS_READ}>
                    <Jobs />
                  </PermissionsGuard>
                ),
              },
              {
                path: 'users',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_USERS_READ}>
                        <Users />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_USERS_WRITE}>
                        <UserCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':userId',
                    children: [
                      {
                        path: 'add',
                        element: (
                          <PermissionsGuard permission={Permission.TENANT_USERS_WRITE}>
                            <UserAdd />
                          </PermissionsGuard>
                        ),
                      },
                      {
                        path: 'edit',
                        element: (
                          <PermissionsGuard permission={Permission.TENANT_USERS_WRITE}>
                            <UserEdit />
                          </PermissionsGuard>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'items',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_ITEMS_READ}>
                        <Items />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':itemId',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_ITEMS_READ}>
                        <Item />
                      </PermissionsGuard>
                    ),
                    children: [
                      {
                        path: '/',
                        element: <ItemDetails />,
                      },
                      {
                        path: 'timeline',
                        element: <ItemTimeline />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'skus',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_ITEMS_READ}>
                        <SKUs />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_ITEMS_WRITE}>
                        <SKUCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':skuId',
                    element: (
                      <PermissionsGuard permission={Permission.TENANT_ITEMS_READ}>
                        <SKU />
                      </PermissionsGuard>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
