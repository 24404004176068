import { Box, CircularProgress } from '@material-ui/core';
import { FC } from 'react';
import PropTypes from 'prop-types';
import { CustomDialog, CustomDialogProps } from './custom-dialog';

type LoadingDialogProps = CustomDialogProps;

export const LoadingDialog: FC<LoadingDialogProps> = ({ title, ...other }) => (
  <CustomDialog {...other} title={title} hideClose>
    <Box
      sx={{
        display: 'flex',
        gridGap: '1em',
      }}
    >
      <CircularProgress
        size="5rem"
        sx={{
          color: 'text.secondary',
          margin: 'auto',
        }}
      />
    </Box>
  </CustomDialog>
);

LoadingDialog.propTypes = {
  title: PropTypes.string.isRequired,
};
