import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import type { DateRangePickerProps } from '@material-ui/lab/DateRangePicker';
import type { DateRange, RangeInput } from '@material-ui/lab/DateRangePicker/RangeTypes';
import { Calendar as CalendarIcon } from '../icons/calendar';

interface DateRangeFieldProps extends Omit<DateRangePickerProps<Date>, 'renderInput'> {
  error?: boolean;
  fullWidth: boolean;
  helperText?: any;
  label?: string;
  onChange: (date: DateRange<Date>) => void;
  onAccept?: (date: DateRange<Date>) => void;
  placeholder?: string;
  value: RangeInput<Date>;
  name?: string;
}

export const DateRangeField: FC<DateRangeFieldProps> = (props) => {
  const {
    error,
    fullWidth,
    helperText,
    label,
    onChange,
    onAccept,
    placeholder,
    value,
    name,
    ...other
  } = props;

  return (
    <DateRangePicker
      onChange={onChange}
      onAccept={onAccept}
      renderInput={(
        { InputProps: StartInputProps, ...startRest },
        { InputProps: EndInputProps, ...endRest },
      ) => (
        <Box
          sx={{
            display: 'flex',
            gridGap: '8px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            From
          </Typography>
          <TextField
            {...startRest}
            name={name}
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            sx={{
              '& .MuiFilledInput-root': {
                width: 150,
                backgroundColor: 'background.paper',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'neutral.300',
                borderRadius: 1,
                boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
                px: 1.5,
                py: 0.75,
                transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
                '&:hover': {
                  backgroundColor: 'background.paper',
                },
                '&.Mui-focused': {
                  backgroundColor: 'background.paper',
                  boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                },
                '& .MuiFilledInput-input': {
                  fontSize: 14,
                  height: 'unset',
                  lineHeight: 1.6,
                  p: 0,
                },
                '&.Mui-disabled': {
                  backgroundColor: 'action.disabledBackground',
                  boxShadow: 'none',
                  borderColor: alpha('#D6DBE1', 0.5),
                },
              },
            }}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              ...StartInputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: 'text.primary',
                fontSize: 14,
                fontWeight: 500,
                mb: 0.5,
                position: 'relative',
                transform: 'none',
              },
            }}
          />
          <Typography
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            To
          </Typography>
          <TextField
            {...endRest}
            name={name}
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            sx={{
              '& .MuiFilledInput-root': {
                width: 150,
                backgroundColor: 'background.paper',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'neutral.300',
                borderRadius: 1,
                boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
                px: 1.5,
                py: 0.75,
                transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
                '&:hover': {
                  backgroundColor: 'background.paper',
                },
                '&.Mui-focused': {
                  backgroundColor: 'background.paper',
                  boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                },
                '& .MuiFilledInput-input': {
                  fontSize: 14,
                  height: 'unset',
                  lineHeight: 1.6,
                  p: 0,
                },
                '&.Mui-disabled': {
                  backgroundColor: 'action.disabledBackground',
                  boxShadow: 'none',
                  borderColor: alpha('#D6DBE1', 0.5),
                },
              },
            }}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              ...EndInputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: 'text.primary',
                fontSize: 14,
                fontWeight: 500,
                mb: 0.5,
                position: 'relative',
                transform: 'none',
              },
            }}
          />
        </Box>
      )}
      value={value}
      {...other}
    />
  );
};

DateRangeField.propTypes = {
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};
