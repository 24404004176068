import { AxiosRequestConfig } from 'axios';

export enum BackendType {
  BACKEND = 'BACKEND',
  SCANNER = 'SCANNER',
}

// Backend
export const backendOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
};

// Scanner
export const scannerOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SCANNER_URL,
};

export interface ResponseData<T> {
  status: string;
  data: T;
}

export interface ListResponse<T> {
  data: T[];
  recordsTotal: number;
  recordsFiltered: number;
}

export interface ResponseError {
  status: string;
  error: {
    message: string;
  };
}
