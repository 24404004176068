import { useState, FC } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { useAxios } from '../../hooks/use-axios';
import { ResponseData } from '../../types/axios';
import { Job } from '../../types/job';

interface JobFlagDialogProps {
  job: Job;
  open: boolean;
  onClose: () => void;
}

export const JobFlagDialog: FC<JobFlagDialogProps> = ({ job, open, onClose, ...other }) => {
  const [confirmation, setConfirmation] = useState(false);
  const { axios } = useAxios();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      const url = `/jobs/${job.id}/flag`;

      return axios.post<ResponseData<Job>>(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('jobs');
        queryClient.invalidateQueries('job');
        queryClient.invalidateQueries('item-events');
        toast.success('Event result was reported');
      },
      onError: () => {
        toast.error('Unable to report event');
      },
      onSettled: () => {
        onClose();
      },
    },
  );

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => setConfirmation(false),
      }}
      // fullWidth
      {...other}
    >
      <DialogTitle>Report as Incorrect</DialogTitle>
      <DialogContent>
        <Typography pb={2}>
          Reported findings will be evaluated to identify the source of unusual behavior.
        </Typography>
        <FormControlLabel
          label="I'm sure that system result was incorrect"
          labelPlacement="end"
          control={
            <Checkbox checked={confirmation} onChange={(_, value) => setConfirmation(value)} />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          color="primary"
          onClick={() => {
            mutation.mutate();
          }}
          variant="contained"
          disabled={!confirmation}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

JobFlagDialog.defaultProps = {
  open: false,
};

JobFlagDialog.propTypes = {
  // @ts-ignore
  job: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
