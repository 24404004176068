import { FC } from 'react';
import PropTypes from 'prop-types';
import { Job } from '../../types/job';
import { ETemplateType } from '../../templates/types';
import { JobsMenu as JobsMenuDefault } from '../../templates/default/components/job/jobs-menu';
import { JobsMenu as JobsMenuEbay } from '../../templates/ebay/components/job/jobs-menu';

interface JobsMenuProps {
  job: Job;
}

export const JobsMenu: FC<JobsMenuProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <JobsMenuDefault {...props} />;
    case ETemplateType.EBAY:
      return <JobsMenuEbay {...props} />;
  }
};

JobsMenu.propTypes = {
  job: PropTypes.any.isRequired,
};
