export interface CreateRemoteFileRequestData {
  tenantId?: string;
  itemId?: string;
  title: string;
  type: RemoteFileType;
  url?: string;
  ttl: RemoteFileTTL;
}

export enum RemoteFileTTL {
  PERMANENT = 'PERMANENT',
  SHORT = 'SHORT',
}

export enum RemoteFileType {
  S3 = 'S3',
  GC = 'GC',
  URL = 'URL',
}

export interface RemoteFile {
  id: string;
  title: string;
  type: RemoteFileType;
  url: string;
  ttl: RemoteFileTTL;
  preSignedS3Url?: string;
  uploadTime?: number;
}
