import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  display: 'flex',
}));

export const MainLayout: FC = () => (
  <MainLayoutRoot>
    <Outlet />
  </MainLayoutRoot>
);
