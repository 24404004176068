import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, DialogProps, List, ListItem, Typography } from '@material-ui/core';
import { AccessTime as ClockIcon } from '@material-ui/icons';
import { ExclamationOutlined as WarningIcon } from '../../../../icons/exclamation-outlined';
import { CalibrationDialogType } from '../../utils/calibration-helper';
import { CustomDialog } from '../custom-dialog';
import { ButtonContinue } from './button-continue';

type CloseHandler = DialogProps['onClose'];

const noBackdropCloseHandler =
  (closeHandler?: CloseHandler): CloseHandler =>
  (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    closeHandler(event, reason);
  };

interface InfoDialogProps {
  open: boolean;
  onClose: CloseHandler;
  type: CalibrationDialogType;
  onContinue: () => void;
}

export const InfoDialog: FC<InfoDialogProps> = ({ open, onClose, type, onContinue }) => {
  let title = '';
  let content = <></>;
  let handleClose = onClose;
  let hideClose = false;
  switch (type) {
    default:
      return <></>;

    case CalibrationDialogType.CAMERA_STREAM_DIALOG:
      title = 'Camera Stream sharpness';
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gridGap: '1em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridGap: '1em',
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '40px !important',
                }}
              >
                Examine the&nbsp;clarity of the&nbsp;Camera Stream. If it is not as sharp as
                the&nbsp;&quot;Sharp&nbsp;example,&quot; please indicate it by pressing
                &quot;Blurry&quot;.
              </Typography>
            </Box>
            <ButtonContinue onClick={onContinue} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <img
              src="/assets/CalibFlow-camerastream.png"
              alt="Camera Stream Sharpness Instructions"
            />
          </Box>
        </Box>
      );
      break;

    case CalibrationDialogType.ADJUST_FOCUS_DIALOG:
      title = 'Adjust the Camera Focus';
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridGap: '1em',
            }}
          >
            <Box>
              <List
                sx={{
                  fontSize: '40px',
                  listStyleType: 'auto',
                  pl: '60px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                    p: 0,
                  },
                }}
              >
                <ListItem>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '40px !important',
                    }}
                  >
                    Locate the&nbsp;Focus ring. (Avoid touching aperture ring!)
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '40px !important',
                    }}
                  >
                    Loosen the&nbsp;safety screw
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '40px !important',
                      fontWeight: 'bold',
                    }}
                  >
                    Rotate the&nbsp;ring until you reach desired level of sharpness.
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <ButtonContinue onClick={onContinue} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src="/assets/CalibFlow-adjustfocus.jpg" alt="Adjust Focus" />
          </Box>
        </Box>
      );
      break;

    case CalibrationDialogType.REVIEW_DIALOG:
      title = 'Calibration review';
      hideClose = true;
      handleClose = noBackdropCloseHandler(onClose);
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gridGap: '.5em',
          }}
        >
          <CircularProgress color="inherit" size={50} thickness={5} />
          <Typography
            variant="body1"
            sx={{
              fontSize: '40px !important',
            }}
          >
            Uploading data to review the sharpness
          </Typography>
        </Box>
      );
      break;

    case CalibrationDialogType.SECURE_FOCUS_DIALOG:
      title = 'Secure the Focus Ring';
      hideClose = true;
      handleClose = noBackdropCloseHandler(onClose);
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gridGap: '1em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gridGap: '2em',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gridGap: '.5em',
              }}
            >
              <WarningIcon sx={{ fontSize: '90px' }} />
              <Typography
                variant="body1"
                sx={{
                  fontSize: '40px !important',
                }}
              >
                Tighten the&nbsp;safety screw located on a Focus ring to avoid decalibration!
              </Typography>
              <Typography variant="body1" color="text.secondary" pt={5}>
                Ignore if not loosened before.
              </Typography>
            </Box>
            <ButtonContinue onClick={onContinue} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src="/assets/CalibFlow-ifblurry-tighten.jpg" alt="Secure Focus" />
          </Box>
        </Box>
      );
      break;

    case CalibrationDialogType.APPROVAL_DIALOG:
      title = 'Wait for approval';
      hideClose = true;
      handleClose = noBackdropCloseHandler(onClose);
      content = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gridGap: '2em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridGap: '.5em',
            }}
          >
            <ClockIcon sx={{ fontSize: '90px' }} />
            <Typography
              variant="body1"
              sx={{
                fontSize: '40px !important',
              }}
            >
              Process is finished. Wait for the&nbsp;confirmation that camera is calibrated
              successfully, <b>before any protection is being performed.</b>
            </Typography>
          </Box>
          <ButtonContinue onClick={onContinue}>Done</ButtonContinue>
        </Box>
      );
      break;
  }

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      hideClose={hideClose}
      title={title}
      fullWidth
      maxWidth="lg"
    >
      {content}
    </CustomDialog>
  );
};

InfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.number,
  onContinue: PropTypes.func,
};
