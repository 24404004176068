import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { DateRange } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { VerifiedUser as ProtectedIcon, Help as UnableToVerifyIcon } from '@material-ui/icons';
import { CheckCircle as VerifiedIcon } from '../../../../icons/check-circle';
import { XCircle as NotVerifiedIcon } from '../../../../icons/x-circle';
import { Flag as ReportedIcon } from '../../../../icons/flag';
import { useAuth } from '../../../../hooks/use-auth';
import { ListResponse, ResponseData } from '../../../../types/axios';
import { useAxios } from '../../../../hooks/use-axios';
import { EStatusFilter, FlagType, Job } from '../../../../types/job';
import { Badge } from '../../../../components/badge';
import { Tenant } from '../../../../types/tenant';
import { FilterOperator } from '../../../../utils/filter-operators';
import { getJobsRequestStatusParams } from '../../../../utils/jobs-helper';

interface JobsBadgesProps {
  range: DateRange<Date>;
}

const getParams = ({
  range,
  tenant,
  status,
  reported,
}: {
  range: DateRange<Date>;
  tenant?: Tenant;
  status?: EStatusFilter;
  reported?: boolean;
}) => {
  const [from, to] = range;
  let params: any = {
    start: 0,
    length: 0,
    'globalFilter[tenant]': tenant?.id,
    'globalFilter[startedAt]': {
      operator: FilterOperator.BETWEEN,
      value: [from.toISOString(), new Date(to.setHours(23, 59, 59)).toISOString()],
    },
    onlyCount: true,
  };

  if (status) {
    params = {
      ...params,
      ...getJobsRequestStatusParams(status),
    };
  }

  if (reported) {
    params['globalFilter[flag]'] = FlagType.Incorrect;
  }

  return params;
};

const JOBS_URL = '/jobs';

export const JobsBadges: FC<JobsBadgesProps> = ({ range }) => {
  const { tenant } = useAuth();
  const { axios } = useAxios();

  // Protected
  const { data: protectedCount, isLoading: isProtectedCountLoading } = useQuery(
    ['jobs-protected', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.PROTECTED }),
      });

      return recordsFiltered;
    },
  );

  // Verfied
  const { data: verifiedCount, isLoading: isVerifiedCountLoading } = useQuery(
    ['jobs-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.VERIFIED }),
      });

      return recordsFiltered;
    },
  );

  // Unable To Verify
  const { data: unableToVerifyCount, isLoading: isUnableToVerifyCountLoading } = useQuery(
    ['jobs-unable-to-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.UNABLE_TO_VERIFY }),
      });

      return recordsFiltered;
    },
  );

  // Not Verified
  const { data: notVerifiedCount, isLoading: isNotVerifiedCountLoading } = useQuery(
    ['jobs-not-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, status: EStatusFilter.NOT_VERIFIED }),
      });

      return recordsFiltered;
    },
  );

  // Reported
  const { data: reportedCount, isLoading: isReportedCountLoading } = useQuery(
    ['jobs-reported', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams({ range, tenant, reported: true }),
      });

      return recordsFiltered;
    },
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg sm={4} xs={6}>
        {/* Protected */}
        <Badge
          title="Protected"
          value={protectedCount}
          loading={isProtectedCountLoading}
          icon={
            <ProtectedIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          }
        />
      </Grid>
      {/* Verified */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Verified"
          value={verifiedCount}
          loading={isVerifiedCountLoading}
          icon={
            <VerifiedIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          }
        />
      </Grid>
      {/* Unable To Verify */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Unable To Verify"
          value={unableToVerifyCount}
          loading={isUnableToVerifyCountLoading}
          icon={
            <UnableToVerifyIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          }
        />
      </Grid>
      {/* Not Verified */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Not Verified"
          value={notVerifiedCount}
          loading={isNotVerifiedCountLoading}
          icon={<NotVerifiedIcon sx={{ color: 'error.main' }} />}
        />
      </Grid>
      {/* Reported */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Reported"
          value={reportedCount}
          loading={isReportedCountLoading}
          icon={<ReportedIcon sx={{ color: 'error.main' }} />}
        />
      </Grid>
    </Grid>
  );
};

JobsBadges.propTypes = {
  range: PropTypes.any,
};
