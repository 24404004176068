import type { FC } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from '@material-ui/lab';
import { ETemplateType } from '../../templates/types';
import { JobsBadges as JobsBadgesDefault } from '../../templates/default/components/job/jobs-badges';
import { JobsBadges as JobsBadgeEbay } from '../../templates/ebay/components/job/jobs-badges';

interface JobsBadgesProps {
  range: DateRange<Date>;
}

export const JobsBadges: FC<JobsBadgesProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <JobsBadgesDefault {...props} />;
    case ETemplateType.EBAY:
      return <JobsBadgeEbay {...props} />;
  }
};

JobsBadges.propTypes = {
  range: PropTypes.any,
};
