import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { ButtonProps } from '@material-ui/core';
import { CalibrationStep, getLastStep } from '../../utils/calibration-helper';
import { ButtonContinue } from './button-continue';

interface ButtonNextProps extends ButtonProps {
  step: CalibrationStep;
}

export const ButtonNext: FC<ButtonNextProps> = ({ step, ...other }) => {
  const [searchParams] = useSearchParams();
  // Must have scanned calibration card qr code to be able to continue
  if (!searchParams.get('cardId') || step === getLastStep()) {
    return <></>;
  }

  return <ButtonContinue {...other} />;
};

ButtonNext.propTypes = {
  step: PropTypes.number,
};
