import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../../hooks/use-auth';
import { SUPER_ADMIN_TENANT } from '../../../types/tenant';

interface GuestGuardProps {
  children: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, tenant } = useAuth();

  if (isAuthenticated) {
    const redirectPath =
      tenant?.id === SUPER_ADMIN_TENANT.id ? '/users' : `/tenants/${tenant?.id}/events`;
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};
