import { useEffect, FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { ChevronLeft as ChevronLeftIcon } from '../../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../../icons/chevron-right';
import { ChevronDoubleLeft as ChevronDoubleLeftIcon } from '../../../icons/chevron-double-left';
import { ChevronDoubleRight as ChevronDoubleRightIcon } from '../../../icons/chevron-double-right';

export const DEFAULT_PAGE_SIZE = 10;

interface PaginationProps {
  disabled: boolean;
  onPageChange: (newPage: number) => void;
  page: number;
  rowsCount: number;
  pageSize?: number;
  title?: string;
}

const PaginationRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),
}));

export const Pagination: FC<PaginationProps> = (props) => {
  const { disabled, onPageChange, page, rowsCount, pageSize, title, ...other } = props;
  // NOTE: Usually, this should be received from the server
  const pagesCount = useMemo(
    () => Math.ceil(rowsCount / (pageSize || DEFAULT_PAGE_SIZE)),
    [rowsCount, pageSize],
  );
  const isFirstPage = useMemo(() => page <= 1, [page]);
  const isLastPage = useMemo(() => page >= pagesCount, [page, pagesCount]);

  const handlePreviousPage = () => {
    onPageChange?.(page - 1);
  };

  const handleNextPage = () => {
    onPageChange?.(page + 1);
  };

  const handleFirstPage = () => {
    onPageChange?.(1);
  };

  const handleLastPage = () => {
    onPageChange?.(pagesCount);
  };

  useEffect(() => {
    if (page > pagesCount && pagesCount > 0) {
      onPageChange?.(pagesCount);
    }

    if (page <= 0) {
      onPageChange?.(1);
    }
  }, [page, pagesCount, onPageChange]);

  return (
    <PaginationRoot {...other}>
      {pagesCount > 0 && (
        <Typography
          color="textSecondary"
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
          variant="body2"
          whiteSpace="nowrap"
        >
          {title !== undefined ? title : 'Page'}{' '}
          <Typography color="textPrimary" component="span" sx={{ mx: 1 }} variant="subtitle2">
            {page}
          </Typography>
          of{' '}
          <Typography color="textPrimary" component="span" sx={{ mx: 1 }} variant="subtitle2">
            {pagesCount}
          </Typography>
          ( Rows
          <Typography color="textPrimary" component="span" sx={{ mx: 1 }} variant="subtitle2">
            {rowsCount}
          </Typography>
          )
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <IconButton disabled={isFirstPage || disabled} onClick={handleFirstPage}>
        <ChevronDoubleLeftIcon />
      </IconButton>
      <IconButton disabled={isFirstPage || disabled} onClick={handlePreviousPage}>
        <ChevronLeftIcon />
      </IconButton>
      <IconButton disabled={isLastPage || disabled} onClick={handleNextPage}>
        <ChevronRightIcon />
      </IconButton>
      <IconButton disabled={isLastPage || disabled} onClick={handleLastPage}>
        <ChevronDoubleRightIcon />
      </IconButton>
    </PaginationRoot>
  );
};

Pagination.defaultProps = {
  disabled: false,
  page: 1,
  rowsCount: 1,
};

Pagination.propTypes = {
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsCount: PropTypes.number,
  pageSize: PropTypes.number,
  title: PropTypes.string,
};
