import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import { initializeI18n } from '../../i18n';
import { RTL } from '../../components/rtl';
import { useSettings } from '../../contexts/settings-context';
import { useAuth } from '../../hooks/use-auth';
import routes from '../../routes';
import { createCustomTheme } from '../../theme';
import { ScannerProvider } from './contexts/scanner-context';
import { OnlineStatus } from './components/online-status';

export const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isInitialized } = useAuth();

  useEffect(() => {
    initializeI18n(settings.language);
  }, [settings]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: 'light',
  });

  return (
    <ScannerProvider>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          {isInitialized && content}
          <OnlineStatus />
        </RTL>
      </ThemeProvider>
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            maxWidth: '500px',
          },
        }}
      />
    </ScannerProvider>
  );
};
