import { useCallback, useEffect, useState } from 'react';
import type { FC, MouseEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { Box, Container } from '@material-ui/core';
import toast from 'react-hot-toast';
import { JobsTable } from '../../../components/job/jobs-table';
import { EJobResultCode, Job } from '../../../types/job';
import { useAxios } from '../../../hooks/use-axios';
import { JobsNavbar } from '../components/job/jobs-navbar';
import { DEFAULT_PAGE_SIZE } from '../../../components/pagination';
import { useScanner } from '../hooks/use-scanner';
import { ScanMessage, MessageAction, ESocketEvent } from '../contexts/scanner-context';
import { ScannedItem } from '../types/scanned-item';
import { ListResponse, ResponseData } from '../../../types/axios';
import { useAuth } from '../../../hooks/use-auth';
import { SUPER_ADMIN_TENANT } from '../../../types/tenant';
import { getHelmetTitle } from '../../../utils/utils';
import { FilterValue, Sort } from '../../../types/filter';
import { LoadingDialog } from '../components/loading-dialog';
import { permaLogger } from '../../../utils/logger';
import { getJobsRequestStatusParams } from '../../../utils/jobs-helper';

interface JobsData {
  jobs: Job[];
  jobsCount: number;
}

interface Controller {
  filters: Array<FilterValue>;
  page: number;
  query: boolean;
  sort: Sort;
  sortBy: string;
}

export const Jobs: FC = () => {
  const [controller, setController] = useState<Controller>({
    filters: [],
    page: 0,
    query: false,
    sort: 'desc',
    sortBy: 'createdAt',
  });

  const { tenant, user } = useAuth();
  const { socket, loadItem, isItemLoading, calibratedAt } = useScanner();
  const { axios } = useAxios();

  const { data, isLoading, error } = useQuery<JobsData>(['jobs', controller], async () => {
    const params: any = {
      start: controller.page * DEFAULT_PAGE_SIZE,
      length: DEFAULT_PAGE_SIZE,
      sort: controller.sort.toUpperCase(),
      sortBy: controller.sortBy,
      ...getJobsRequestStatusParams(),
      'globalFilter[user]': user?.id,
    };

    if (tenant?.id !== SUPER_ADMIN_TENANT.id) {
      params['globalFilter[tenant]'] = tenant?.id;
    }

    if (controller.query) {
      params['filter[resultCode]'] = EJobResultCode.NOT_VERIFIED;
    }

    const url = `/jobs`;

    const {
      data: { data: responseData },
    } = await axios.get<ResponseData<ListResponse<Job>>>(url, {
      params,
    });

    return {
      jobs: responseData.data,
      jobsCount: responseData.recordsFiltered,
    };
  });

  const handlePageChange = (newPage: number): void => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handleSortChange = (event: MouseEvent<HTMLElement>, property: string): void => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleQueryChange = (value: boolean): void => {
    setController({
      ...controller,
      query: value,
    });
  };

  const messageHandler = useCallback(
    ({ action, data: messageData }: ScanMessage) => {
      if (action === MessageAction.ItemScan && !isItemLoading) {
        if (
          process.env.REACT_APP_CALIBRATION_OPTIONAL === '1' ||
          calibratedAt?.getFullYear() >= 2024
        ) {
          loadItem(messageData as ScannedItem);
        } else {
          permaLogger('[jobs/list] No calibration data found. Please calibrate the camera first.');
          toast.error('No calibration data found. Please calibrate the camera first.');
        }
      }
    },
    [loadItem],
  );

  useEffect(() => {
    if (socket) {
      socket.on(ESocketEvent.ScanEvent, messageHandler);
    }

    return () => {
      socket?.off(ESocketEvent.ScanEvent, messageHandler);
    };
  }, [socket, messageHandler]);

  return (
    <>
      <Helmet>
        <title>Events: List | {getHelmetTitle()}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          pt: '120px',
        }}
      >
        <JobsNavbar query={controller.query} onQueryChange={handleQueryChange} />
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: '0 !important',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <JobsTable
              error={error?.toString()}
              isLoading={isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              jobs={data?.jobs}
              jobsCount={data?.jobsCount}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Box>
        </Container>
      </Box>
      <LoadingDialog open={isItemLoading} title="Loading item" />
    </>
  );
};
