import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ETemplateType } from '../templates/types';
import { DashboardNavbarMenu as DashboardNavbarMenuDefault } from '../templates/default/components/dashboard-navbar-menu';
import { DashboardNavbarMenu as DashboardNavbarMenuAuthentique } from '../templates/authentique/components/dashboard-navbar-menu';
import { DashboardNavbarMenu as DashboardNavbarMenuEbay } from '../templates/ebay/components/dashboard-navbar-menu';

interface DashboardNavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

export const DashboardNavbarMenu: FC<DashboardNavbarMenuProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <DashboardNavbarMenuDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <DashboardNavbarMenuAuthentique {...props} />;
    case ETemplateType.EBAY:
      return <DashboardNavbarMenuEbay {...props} />;
  }
};

DashboardNavbarMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
