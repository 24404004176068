import { createSvgIcon } from '@material-ui/core/utils';

export const Flag = createSvgIcon(
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.50003 7.2001C4.50003 6.24532 4.87932 5.32964 5.55445 4.65451C6.22958 3.97938 7.14525 3.6001 8.10003 3.6001H20.1C20.3229 3.6001 20.5413 3.66216 20.7309 3.77932C20.9205 3.89648 21.0737 4.06411 21.1733 4.26344C21.273 4.46277 21.3152 4.68591 21.2952 4.90786C21.2752 5.12982 21.1937 5.34181 21.06 5.5201L18 9.6001L21.06 13.6801C21.1937 13.8584 21.2752 14.0704 21.2952 14.2923C21.3152 14.5143 21.273 14.7374 21.1733 14.9368C21.0737 15.1361 20.9205 15.3037 20.7309 15.4209C20.5413 15.538 20.3229 15.6001 20.1 15.6001H8.10003C7.78177 15.6001 7.47655 15.7265 7.2515 15.9516C7.02646 16.1766 6.90003 16.4818 6.90003 16.8001V20.4001C6.90003 20.7184 6.7736 21.0236 6.54856 21.2486C6.32351 21.4737 6.01829 21.6001 5.70003 21.6001C5.38177 21.6001 5.07655 21.4737 4.8515 21.2486C4.62646 21.0236 4.50003 20.7184 4.50003 20.4001V7.2001Z"
      fill="currentColor"
    />
  </svg>,
  'Flag',
);
