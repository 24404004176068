import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { DateRange } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { VerifiedUser as ProtectedIcon, Help as UnableToVerifyIcon } from '@material-ui/icons';
import { CheckCircle as VerifiedIcon } from '../../../../icons/check-circle';
import { XCircle as NotVerifiedIcon } from '../../../../icons/x-circle';
import { useAuth } from '../../../../hooks/use-auth';
import { ListResponse, ResponseData } from '../../../../types/axios';
import { useAxios } from '../../../../hooks/use-axios';
import { EStatusFilter, Job } from '../../../../types/job';
import { Badge } from '../../../../components/badge';
import { Tenant } from '../../../../types/tenant';
import { FilterOperator } from '../../../../utils/filter-operators';

interface JobsBadgesProps {
  range: DateRange<Date>;
}

const getParams = (range: DateRange<Date>, tenant?: Tenant, status?: EStatusFilter) => {
  const [from, to] = range;
  const params: any = {
    start: 0,
    length: 0,
    'globalFilter[tenant]': tenant?.id,
    'globalFilter[startedAt]': {
      operator: FilterOperator.BETWEEN,
      value: [from.toISOString(), new Date(to.setHours(23, 59, 59)).toISOString()],
    },
    onlyCount: true,
  };

  if (status) {
    params.status = status;
  }

  return params;
};

const JOBS_URL = '/jobs';

export const JobsBadges: FC<JobsBadgesProps> = ({ range }) => {
  const { tenant } = useAuth();
  const { axios } = useAxios();

  // Processed
  const { data: processedCount, isLoading: isProcessedCountLoading } = useQuery(
    ['jobs-processed', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams(range, tenant),
      });

      return recordsFiltered;
    },
  );

  // Protected
  const { data: protectedCount, isLoading: isProtectedCountLoading } = useQuery(
    ['jobs-protected', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams(range, tenant, EStatusFilter.PROTECTED),
      });

      return recordsFiltered;
    },
  );

  // Verfied
  const { data: verifiedCount, isLoading: isVerifiedCountLoading } = useQuery(
    ['jobs-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams(range, tenant, EStatusFilter.VERIFIED),
      });

      return recordsFiltered;
    },
  );

  // Unable To Verify
  const { data: unableToVerifyCount, isLoading: isUnableToVerifyCountLoading } = useQuery(
    ['jobs-unable-to-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams(range, tenant, EStatusFilter.UNABLE_TO_VERIFY),
      });

      return recordsFiltered;
    },
  );

  // Not Verified
  const { data: notVerifiedCount, isLoading: isNotVerifiedCountLoading } = useQuery(
    ['jobs-not-verified', tenant, range],
    async () => {
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(JOBS_URL, {
        params: getParams(range, tenant, EStatusFilter.NOT_VERIFIED),
      });

      return recordsFiltered;
    },
  );

  return (
    <Grid container spacing={3}>
      {/* Processed */}
      <Grid item lg sm={4} xs={6}>
        <Badge title="Processed" value={processedCount} loading={isProcessedCountLoading} />
      </Grid>
      <Grid item lg sm={4} xs={6}>
        {/* Protected */}
        <Badge
          title="Protected"
          value={protectedCount}
          loading={isProtectedCountLoading}
          icon={
            <ProtectedIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          }
        />
      </Grid>
      {/* Verified */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Verified"
          value={verifiedCount}
          loading={isVerifiedCountLoading}
          icon={
            <VerifiedIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          }
        />
      </Grid>
      {/* Unable To Verify */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Unable To Verify"
          value={unableToVerifyCount}
          loading={isUnableToVerifyCountLoading}
          icon={
            <UnableToVerifyIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          }
        />
      </Grid>
      {/* Not Verified */}
      <Grid item lg sm={4} xs={6}>
        <Badge
          title="Not Verified"
          value={notVerifiedCount}
          loading={isNotVerifiedCountLoading}
          icon={<NotVerifiedIcon sx={{ color: 'error.main' }} />}
        />
      </Grid>
    </Grid>
  );
};

JobsBadges.propTypes = {
  range: PropTypes.any,
};
