import { FC, useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { storage } from '../utils/storage';
import { FilterValue } from '../types/filter';

interface StoredControllers {
  [key: string]: { filters: Array<FilterValue>; page: number };
}

const resetStoredControllers = () => {
  const storedControllers: StoredControllers | undefined = JSON.parse(
    storage.controllers.get() || '{}',
  );

  if (storedControllers && Object.keys(storedControllers).length) {
    const newControllers: StoredControllers = {};
    Object.entries(storedControllers).forEach(([key, controller]) => {
      newControllers[key] = { ...controller, filters: [], page: 0 };
    });

    storage.controllers.set(JSON.stringify(newControllers));
  }
};

export const TenantGuard: FC = () => {
  const { tenantId } = useParams();
  const { tenant, setTenantById } = useAuth();

  useEffect(() => {
    if (tenant?.id !== tenantId) {
      setTenantById(tenantId);

      // Reset stored table controllers
      resetStoredControllers();
    }
  }, [tenant, tenantId]);

  return <Outlet />;
};
