import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ETemplateType } from '../templates/types';
import { DashboardSidebar as DashboardSidebarDefault } from '../templates/default/components/dashboard-sidebar';
import { DashboardSidebar as DashboardSidebarAuthentique } from '../templates/authentique/components/dashboard-sidebar';
import { DashboardSidebar as DashboardSidebarEbay } from '../templates/ebay/components/dashboard-sidebar';

interface DashboardSidebarProps {
  onPin: () => void;
  pinned: boolean;
}

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <DashboardSidebarDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <DashboardSidebarAuthentique {...props} />;
    case ETemplateType.EBAY:
      return <DashboardSidebarEbay {...props} />;
  }
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool,
};
