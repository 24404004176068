import type { PartialRouteObject } from 'react-router';
import { ETemplateType } from './templates/types';
import routesDefault from './templates/default/routes';
import routesAuthentique from './templates/authentique/routes';
import routesEbay from './templates/ebay/routes';
import routesEbayOperator from './templates/ebay-operator/routes';

const getRoutes = (): PartialRouteObject[] => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return routesDefault;
    case ETemplateType.AUTHENTIQUE:
      return routesAuthentique;
    case ETemplateType.EBAY:
      return routesEbay;
    case ETemplateType.EBAY_OPERATOR:
      return routesEbayOperator;
  }
};

const routes = getRoutes();

export default routes;
