import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { X as XIcon } from '../../../icons/x';

export interface CustomDialogProps extends DialogProps {
  title: string;
  hideClose?: boolean;
}

export const CustomDialog: FC<CustomDialogProps> = ({
  title,
  hideClose,
  children,
  onClose,
  ...others
}) => (
  <Dialog {...others} onClose={onClose}>
    <DialogTitle
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        height: 120,
        py: 0,
        pr: 0,
      }}
    >
      <Typography
        color="textPrimary"
        variant="inherit"
        sx={{
          p: 0,
          mr: 4
        }}
      >
        {title}
      </Typography>
      {!hideClose && (
        <IconButton
          size="large"
          onClick={() => onClose(null, 'escapeKeyDown')}
          title="Close"
          color="inherit"
          sx={{
            borderRadius: '0 !important',
            background: '#d9d9d9',
            width: 120,
            height: 120,
          }}
        >
          <XIcon fontSize="large" />
        </IconButton>
      )}
    </DialogTitle>
    <Divider />
    <DialogContent
      sx={{
        p: '42px',
      }}
    >
      {children}
    </DialogContent>
  </Dialog>
);

CustomDialog.propTypes = {
  title: PropTypes.string,
  hideClose: PropTypes.bool,
  children: PropTypes.any,
  onClose: PropTypes.func,
};
