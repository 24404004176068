import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonProps, Typography } from '@material-ui/core';

export const CustomButton: FC<ButtonProps> = ({ children, ...other }) => (
  <Button
    color="primary"
    variant="contained"
    size="large"
    {...other}
    sx={{
      height: 120,
      width: 430,
      backgroundColor: `#1A1A1A !important`,
      ...other.sx,
    }}
  >
    <Typography
      variant="subtitle1"
      color="#FFFFFF"
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gridGap: '.5em',
      }}
    >
      {children}
    </Typography>
  </Button>
);

CustomButton.propTypes = {
  children: PropTypes.any,
};
