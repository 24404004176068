import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import { BackendType, ResponseError, backendOptions, scannerOptions } from '../types/axios';
// eslint-disable-next-line import/no-cycle
import { useAuth } from './use-auth';

// Authorization
const configAuthRequest = async (config: AxiosRequestConfig) => {
  logger('[axios/request]', config);
  return config;
};
// Response
const configAuthResponse = (response: AxiosResponse) => {
  logger('[axios/response]', response);
  return response;
};

const authRequest = (noAuth?: boolean, token?: string) => (config: AxiosRequestConfig) => {
  if (!noAuth && token) {
    return {
      ...config,
      headers: {
        authorization: `Bearer ${token}`,
        ...config.headers,
      },
    };
  }

  return config;
};

const authResponseError =
  (isAuthenticated: boolean, refresh: () => Promise<void>) =>
  async (error: AxiosError<ResponseError>) => {
    logger('[axios/response/error]', error.response);
    if (error?.response?.status === 401 && isAuthenticated) {
      await refresh();
    }

    return Promise.reject(error);
  };

interface AxiosInitArgs {
  isAuthenticated: boolean;
  refresh: () => Promise<void>;
  noAuth?: boolean;
  token?: string;
  backendType?: BackendType;
}

const initialize = ({
  isAuthenticated,
  refresh,
  noAuth,
  token,
  backendType,
}: AxiosInitArgs): AxiosState => {
  let options: AxiosRequestConfig;
  switch (backendType) {
    default:
    case BackendType.BACKEND:
      options = backendOptions;
      break;
    case BackendType.SCANNER:
      options = scannerOptions;
      break;
  }

  const axInstance = axios.create(options);
  axInstance.interceptors.request.use(configAuthRequest);
  axInstance.interceptors.request.use(authRequest(noAuth, token));
  axInstance.interceptors.response.use(
    configAuthResponse,
    authResponseError(isAuthenticated, refresh),
  );

  return {
    axios: axInstance,
  };
};

export interface AxiosState {
  axios: AxiosInstance;
}

export const useAxios = (noAuth?: boolean, backendType?: BackendType): AxiosState => {
  const { isAuthenticated, token, refresh } = useAuth();
  const [axiosState, setAxiosState] = useState<AxiosState>(
    initialize({ isAuthenticated, refresh, noAuth, token, backendType }),
  );

  useEffect(() => {
    setAxiosState(initialize({ isAuthenticated, refresh, noAuth, token, backendType }));
  }, [isAuthenticated, token, refresh, noAuth, token, backendType]);

  return axiosState;
};
