import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, IconButton, Skeleton, Tooltip, Typography } from '@material-ui/core';
import { QuestionMarkOutlined as QuestionMarkIcon } from '../icons/question-mark-outlined';

interface BadgeProps {
  title: string;
  value?: string | number;
  tooltip?: string;
  icon?: ReactNode;
  loading?: boolean;
}

export const Badge: FC<BadgeProps> = ({ title, value, tooltip, icon, loading }) => (
  <Card sx={{ height: '100%' }} variant="outlined">
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        gridGap: '16px',
        alignItems: 'center',
        p: 2,
      }}
    >
      {icon}
      <Box
        sx={{
          display: 'grid',
          alignItems: 'flex-start',
          // p: 2,
          height: '100%',
          gridTemplateRows: '1fr auto',
          position: 'relative',
        }}
      >
        <Typography color="textSecondary" variant="overline" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        {loading ? (
          <Skeleton width={50} />
        ) : (
          <Typography color="textPrimary" variant="h6">
            {value !== undefined ? value : 'N/A'}
          </Typography>
        )}
        {Boolean(tooltip) && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <Tooltip title={tooltip}>
              <IconButton
                size="small"
                sx={{
                  borderRadius: '50%',
                  opacity: 0.5,

                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <QuestionMarkIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  </Card>
);

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltip: PropTypes.string,
  icon: PropTypes.any,
  loading: PropTypes.bool,
};
