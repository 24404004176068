import type { FC } from 'react';
import PropTypes from 'prop-types';

interface LogoProps {
  variant: 'light' | 'dark';
}

export const Logo: FC<LogoProps> = (props) => {
  const { variant } = props;

  const color = variant === 'light' ? '#ffffff' : '#000000';

  const size = '40';
  const viewBox = '0 0 500 500';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width={size}
      height={size}
      viewBox={viewBox}
      xmlSpace="preserve"
    >
      <desc>Created with Fabric.js 4.6.0</desc>
      <g transform="matrix(1.12 0 0 1.15 165.99 250)" id="2T54TvymgGBX5nRBoo2aJ">
        <path
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeDashoffset: 0,
            strokeLinejoin: 'miter',
            strokeMiterlimit: 4,
            fill: color,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          vectorEffect="non-scaling-stroke"
          transform=" translate(-100.25, -58.48)"
          d="M 58.48 14.2 C 34.10834620003858 14.31582296555893 14.412422788688374 34.10557098515744 14.412422788688374 58.477500000000006 C 14.412422788688374 82.84942901484256 34.10834620003858 102.63917703444108 58.48 102.75500000000001 L 142.023 102.75500000000001 C 157.89195531021537 102.83041504895144 172.58784910145198 94.40770341287013 180.54417692272992 80.67721625226365 C 188.50050474400786 66.94672909165715 188.50050474400786 50.00827090834286 180.54417692272992 36.277783747736365 C 172.58784910145198 22.547296587129864 157.89195531021537 14.124584951048575 142.023 14.200000000000003 L 58.48 14.200000000000003 M 58.48 3.552713678800501e-15 L 142.023 3.552713678800501e-15 C 174.3206121701048 3.552713678800501e-15 200.503 26.182387829895188 200.503 58.47999999999999 C 200.503 90.77761217010479 174.32061217010482 116.95999999999998 142.02300000000002 116.96 L 58.48 116.96 C 26.182387829895198 116.96 0 90.77761217010479 0 58.48 C 0 26.182387829895205 26.182387829895198 0 58.48 0 Z"
          strokeLinecap="round"
        />
      </g>
      <g transform="matrix(1.12 0 0 1.15 334.01 250)" id="aeYtfqTFGGpMCY9DR1wFF">
        <path
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeDashoffset: 0,
            strokeLinejoin: 'miter',
            strokeMiterlimit: 4,
            fill: color,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          vectorEffect="non-scaling-stroke"
          transform=" translate(-100.25, -58.48)"
          d="M 58.48 14.2 C 34.10834620003858 14.31582296555893 14.412422788688374 34.10557098515744 14.412422788688374 58.477500000000006 C 14.412422788688374 82.84942901484256 34.10834620003858 102.63917703444108 58.48 102.75500000000001 L 142.023 102.75500000000001 C 157.89195531021537 102.83041504895144 172.58784910145198 94.40770341287013 180.54417692272992 80.67721625226365 C 188.50050474400786 66.94672909165715 188.50050474400786 50.00827090834286 180.54417692272992 36.277783747736365 C 172.58784910145198 22.547296587129864 157.89195531021537 14.124584951048575 142.023 14.200000000000003 L 58.48 14.200000000000003 M 58.48 3.552713678800501e-15 L 142.023 3.552713678800501e-15 C 174.3206121701048 3.552713678800501e-15 200.503 26.182387829895188 200.503 58.47999999999999 C 200.503 90.77761217010479 174.32061217010482 116.95999999999998 142.02300000000002 116.96 L 58.48 116.96 C 26.182387829895198 116.96 0 90.77761217010479 0 58.48 C 0 26.182387829895205 26.182387829895198 0 58.48 0 Z"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

Logo.defaultProps = {
  variant: 'dark',
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};
