import type { FC } from 'react';
import { ETemplateType } from './templates/types';
import { App as DefaultApp } from './templates/default/app';
import { App as EbayOperatorApp } from './templates/ebay-operator/app';

export const App: FC = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <DefaultApp />;
    case ETemplateType.EBAY_OPERATOR:
      return <EbayOperatorApp />;
  }
};
