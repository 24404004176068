import { createSvgIcon } from '@material-ui/core/utils';

export const ShieldSuccess = createSvgIcon(
  <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3999 38.2002C19.3999 38.2002 33.3999 31.2002 33.3999 20.7002V8.4502L19.3999 3.2002L5.3999 8.4502V20.7002C5.3999 31.2002 19.3999 38.2002 19.3999 38.2002Z"
      fill="#008032"
      stroke="#008032"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0255 15.667L18.0255 23.667L14.3892 20.0306"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>,

  'ShieldSuccess',
);
