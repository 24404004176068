import { createSvgIcon } from '@material-ui/core/utils';

export const VP = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20L3 5H9.75093L15.3755 14.3732L12 20Z" fill="currentColor" />
    <path
      d="M18.1877 9.6866L15.3755 5H17.6245L19.3141 7.8134L18.1877 9.6866Z"
      fill="currentColor"
    />
    <path d="M19.3141 5L20.1571 6.4067L20.4741 5.87919L19.9445 5H19.3141Z" fill="currentColor" />
    <path
      d="M20.7352 5.4378L20.4741 5H20.683L20.8433 5.26196L20.7352 5.4378Z"
      fill="currentColor"
    />
    <path d="M20.8433 5L20.9217 5.13277L20.9515 5.08254L20.903 5H20.8433Z" fill="currentColor" />
    <path
      d="M20.9739 5.03947L20.9515 5H20.9702L20.9851 5.02512L20.9739 5.03947Z"
      fill="currentColor"
    />
    <path d="M20.9851 5L20.9925 5.01077L20.9963 5.00718L20.9925 5H20.9851Z" fill="currentColor" />
    <path d="M20.9963 5.00359V5L21 5.00359H20.9963Z" fill="currentColor" />
  </svg>,
  'VP',
);
