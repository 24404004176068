import { Box, Container } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { Navigate, useSearchParams } from 'react-router-dom';
import { CalibrationNavbar } from '../components/calibration/calibration-navbar';
import { ComparisonContainer } from '../components/calibration/comparison-container';
import { InfoDialog } from '../components/calibration/info-dialog';
import { InsertCardContent } from '../components/calibration/insert-card-content';
import { useDialog } from '../../../hooks/use-dialog';
import { useScanner } from '../hooks/use-scanner';
import {
  CalibrationDialogType,
  CalibrationStep,
  getFirstStep,
  getLastStep,
} from '../utils/calibration-helper';
import { getHelmetTitle } from '../../../utils/utils';
import { ECameraStreamMode } from '../contexts/scanner-context';

export const Calibration: FC = () => {
  const [searchParams] = useSearchParams();
  const { calibrate, getStream, startStream, stopStream } = useScanner();
  const [step, setStep] = useState<CalibrationStep>(getFirstStep());
  const [dialogType, setDialogType] = useState<CalibrationDialogType>(
    CalibrationDialogType.CAMERA_STREAM_DIALOG,
  );
  const [focusChanged, setFocusChanged] = useState<boolean>(false);
  const [openDialog, handleOpenDialog, handleCloseDialog] = useDialog();

  const handleCloseCalibration = () => {
    setStep(getFirstStep());
    let success = false;
    if (step === CalibrationStep.FOCUS_COMPARISON) {
      stopStream();
      if (dialogType === CalibrationDialogType.APPROVAL_DIALOG) {
        toast.success('Calibration done');
        success = true;
      }
    }
    if (!success) {
      toast.error('Calibration canceled');
    }

    handleCloseDialog();
    // navigate('/', { replace: true });
    window.location.href = '/';
  };

  const handleNextStep = () => {
    setStep((prev) => {
      if (prev === CalibrationStep.INSERT_INFO) {
        setDialogType(CalibrationDialogType.CAMERA_STREAM_DIALOG);
        handleOpenDialog();
      }
      return Math.min(getLastStep(), prev + 1);
    });
  };

  const handlePrevStep = () => {
    if (step === CalibrationStep.FOCUS_COMPARISON) {
      stopStream();
    }
    setStep((prev) => Math.max(getFirstStep(), prev - 1));
  };

  const handleBlurry = () => {
    setFocusChanged(true);
    setDialogType(CalibrationDialogType.ADJUST_FOCUS_DIALOG);
    handleOpenDialog();
  };

  const handleSharp = () => {
    setDialogType(CalibrationDialogType.REVIEW_DIALOG);
    handleOpenDialog();
    stopStream();
    calibrate(searchParams.get('cardId'), (data, error) => {
      if (error) {
        // startStream();
        handleCloseDialog();
      }
      if (data) {
        setDialogType(
          focusChanged
            ? CalibrationDialogType.SECURE_FOCUS_DIALOG
            : CalibrationDialogType.APPROVAL_DIALOG,
        );
      }
    });
  };

  const handleContinue = () => {
    switch (dialogType) {
      default:
        handleCloseDialog();
        break;
      case CalibrationDialogType.SECURE_FOCUS_DIALOG:
        setDialogType(CalibrationDialogType.APPROVAL_DIALOG);
        break;
      case CalibrationDialogType.APPROVAL_DIALOG:
        handleCloseCalibration();
    }
  };

  useEffect(() => {
    if (!searchParams.get('cardId')) {
      toast.error('You must scan QR code on calibration card first');
      // navigate('/');
      window.location.href = '/';
    }
  }, [searchParams]);

  if (!searchParams.get('cardId')) {
    toast.error('You must scan QR code on calibration card first');
    return <Navigate to="/" replace />;
  }

  useEffect(
    () => () => {
      handleOpenDialog();
      stopStream();
    },
    [],
  );

  useEffect(() => {
    if (step === CalibrationStep.FOCUS_COMPARISON) {
      startStream();
    }
  }, [step]);

  const getContent = useCallback(
    () =>
      step === CalibrationStep.FOCUS_COMPARISON ? (
        <ComparisonContainer
          streamUrl={getStream(ECameraStreamMode.CALIBRATION)}
          onBlurry={handleBlurry}
          onSharp={handleSharp}
        />
      ) : (
        <InsertCardContent />
      ),
    [step, handleBlurry, handleSharp],
  );

  return (
    <>
      <Helmet>
        <title>Calibration | {getHelmetTitle()}</title>
      </Helmet>
      <Box
        sx={{
          height: '100vh',
          backgroundColor: 'background.default',
          flexGrow: 1,
          pt: '120px',
        }}
      >
        <CalibrationNavbar
          step={step}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
          onClose={handleCloseCalibration}
        />
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 120px)',
            p: '0 !important',
          }}
        >
          {getContent()}
          <InfoDialog
            open={openDialog}
            onClose={handleCloseDialog}
            type={dialogType}
            onContinue={handleContinue}
          />
        </Container>
      </Box>
    </>
  );
};
