import { ETemplateType } from './templates/types';
import {
  lightPrimary as lightPrimaryDefault,
  lightSecondary as lightSecondaryDefault,
  lightError as lightErrorDefault,
  lightWarning as lightWarningDefault,
  lightInfo as lightInfoDefault,
  lightSuccess as lightSuccessDefault,
  lightBackground as lightBackgroundDefault,
  lightText as lightTextDefault,
  lightNeutral as lightNeutralDefault,
  darkPrimary as darkPrimaryDefault,
  darkSecondary as darkSecondaryDefault,
  darkError as darkErrorDefault,
  darkWarning as darkWarningDefault,
  darkInfo as darkInfoDefault,
  darkSuccess as darkSuccessDefault,
  darkBackground as darkBackgroundDefault,
  darkText as darkTextDefault,
  darkNeutral as darkNeutralDefault,
} from './templates/default/colors';
import {
  lightPrimary as lightPrimaryEbayOperator,
  lightSecondary as lightSecondaryEbayOperator,
  lightError as lightErrorEbayOperator,
  lightWarning as lightWarningEbayOperator,
  lightInfo as lightInfoEbayOperator,
  lightSuccess as lightSuccessEbayOperator,
  lightBackground as lightBackgroundEbayOperator,
  lightText as lightTextEbayOperator,
  lightNeutral as lightNeutralEbayOperator,
  darkPrimary as darkPrimaryEbayOperator,
  darkSecondary as darkSecondaryEbayOperator,
  darkError as darkErrorEbayOperator,
  darkWarning as darkWarningEbayOperator,
  darkInfo as darkInfoEbayOperator,
  darkSuccess as darkSuccessEbayOperator,
  darkBackground as darkBackgroundEbayOperator,
  darkText as darkTextEbayOperator,
  darkNeutral as darkNeutralEbayOperator,
} from './templates/ebay-operator/colors';

interface TemplatesColor {
  colorDefault: any;
  colorEbayOperator: any;
}

const getTemplateColor = ({ colorDefault, colorEbayOperator }: TemplatesColor) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return colorDefault;
    case ETemplateType.EBAY_OPERATOR:
      return colorEbayOperator;
  }
};

export const lightPrimary = getTemplateColor({
  colorDefault: lightPrimaryDefault,
  colorEbayOperator: lightPrimaryEbayOperator,
});

export const lightSecondary = getTemplateColor({
  colorDefault: lightSecondaryDefault,
  colorEbayOperator: lightSecondaryEbayOperator,
});

export const lightError = getTemplateColor({
  colorDefault: lightErrorDefault,
  colorEbayOperator: lightErrorEbayOperator,
});

export const lightWarning = getTemplateColor({
  colorDefault: lightWarningDefault,
  colorEbayOperator: lightWarningEbayOperator,
});

export const lightInfo = getTemplateColor({
  colorDefault: lightInfoDefault,
  colorEbayOperator: lightInfoEbayOperator,
});

export const lightSuccess = getTemplateColor({
  colorDefault: lightSuccessDefault,
  colorEbayOperator: lightSuccessEbayOperator,
});

export const lightBackground = getTemplateColor({
  colorDefault: lightBackgroundDefault,
  colorEbayOperator: lightBackgroundEbayOperator,
});

export const lightText = getTemplateColor({
  colorDefault: lightTextDefault,
  colorEbayOperator: lightTextEbayOperator,
});

export const lightNeutral = getTemplateColor({
  colorDefault: lightNeutralDefault,
  colorEbayOperator: lightNeutralEbayOperator,
});

export const darkPrimary = getTemplateColor({
  colorDefault: darkPrimaryDefault,
  colorEbayOperator: darkPrimaryEbayOperator,
});

export const darkSecondary = getTemplateColor({
  colorDefault: darkSecondaryDefault,
  colorEbayOperator: darkSecondaryEbayOperator,
});

export const darkError = getTemplateColor({
  colorDefault: darkErrorDefault,
  colorEbayOperator: darkErrorEbayOperator,
});

export const darkWarning = getTemplateColor({
  colorDefault: darkWarningDefault,
  colorEbayOperator: darkWarningEbayOperator,
});

export const darkInfo = getTemplateColor({
  colorDefault: darkInfoDefault,
  colorEbayOperator: darkInfoEbayOperator,
});

export const darkSuccess = getTemplateColor({
  colorDefault: darkSuccessDefault,
  colorEbayOperator: darkSuccessEbayOperator,
});

export const darkBackground = getTemplateColor({
  colorDefault: darkBackgroundDefault,
  colorEbayOperator: darkBackgroundEbayOperator,
});

export const darkText = getTemplateColor({
  colorDefault: darkTextDefault,
  colorEbayOperator: darkTextEbayOperator,
});

export const darkNeutral = getTemplateColor({
  colorDefault: darkNeutralDefault,
  colorEbayOperator: darkNeutralEbayOperator,
});
