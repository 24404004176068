import type { FC } from 'react';
import { ETemplateType } from '../templates/types';
import { Jobs as JobsDefault } from '../templates/default/containers/jobs';
import { Jobs as JobsEbayOperator } from '../templates/ebay-operator/containers/jobs';
import { Jobs as JobsEbay } from '../templates/ebay/containers/jobs';

export const Jobs: FC = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <JobsDefault />;
    case ETemplateType.EBAY:
      return <JobsEbay />;
    case ETemplateType.EBAY_OPERATOR:
      return <JobsEbayOperator />;
  }
};
