import { createSvgIcon } from '@material-ui/core/utils';

export const ShieldUnknown = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3999 38.2002C19.3999 38.2002 33.3999 31.2002 33.3999 20.7002V8.4502L19.3999 3.2002L5.3999 8.4502V20.7002C5.3999 31.2002 19.3999 38.2002 19.3999 38.2002Z"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 24.5V24"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M16.0898 17C16.3249 16.3317 16.789 15.7681 17.3998 15.4091C18.0106 15.0501 18.7287 14.9189 19.427 15.0387C20.1253 15.1585 20.7587 15.5215 21.2149 16.0635C21.6712 16.6055 21.9209 17.2915 21.9198 18C21.9198 20 18.9198 21 18.9198 21"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>,
  'ShieldUnknown',
);
