import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import logger from './logger';

// Authorization
const configAuthRequest = async (config: AxiosRequestConfig) => {
  logger('[axios/request]', config);
  return config;
};
// Response
const configAuthResponse = (response: AxiosResponse) => {
  logger('[axios/response]', response);
  return response;
};
// Response authorization
const configAuthResponseError = (error: AxiosError) => {
  logger('[axios/response/error]', error.response);
  return Promise.reject(error);
};

// Backend
const backendOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
};

const axiosBackend = axios.create(backendOptions);
axiosBackend.interceptors.request.use(configAuthRequest);
axiosBackend.interceptors.response.use(configAuthResponse, configAuthResponseError);

export default axiosBackend;
