import { createSvgIcon } from '@material-ui/core/utils';

export const Analyzing = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 20V10" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M12 20V4" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M6 20V14" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>,
  'Analyzing',
);
