import { FC, useEffect } from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useOnlineStatus } from '../../../hooks/use-online-status';
import { ExclamationOutlined as WarningIcon } from '../../../icons/exclamation-outlined';

export const OnlineStatus: FC = () => {
  const { isOnline } = useOnlineStatus();

  useEffect(() => {
    if (isOnline) {
      toast.success('You are online');
    }
  }, [isOnline]);

  return (
    <Dialog
      open={!isOnline}
      maxWidth="md"
      sx={{
        zIndex: 9999,
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          gridGap: '0.5rem',
          border: '1rem solid #CC1F2B',
        }}
      >
        <WarningIcon />
        <Typography variant="subtitle1">You have no internet connection.</Typography>
      </DialogContent>
    </Dialog>
  );
};
