import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ETemplateType } from '../templates/types';
import { GuestGuard as GuestGuardDefault } from '../templates/default/components/guest-guard';
import { GuestGuard as GuestGuardAuthentique } from '../templates/authentique/components/guest-guard';
import { GuestGuard as GuestGuardEbayOperator } from '../templates/ebay-operator/components/guest-guard';

interface GuestGuardProps {
  children: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <GuestGuardDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <GuestGuardAuthentique {...props} />;
    case ETemplateType.EBAY_OPERATOR:
      return <GuestGuardEbayOperator {...props} />;
  }
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};
