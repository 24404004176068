import { useEffect, useState, useMemo } from 'react';
import type { FC, ElementType } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton, List } from '@material-ui/core';
import { HorizontalSplitRounded as JobsIcon } from '@material-ui/icons';
import { DashboardSidebarItem } from '../../../components/dashboard-sidebar-item';
import { Scrollbar } from '../../../components/scrollbar';
import { ChevronLeft as ChevronLeftIcon } from '../../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../../icons/chevron-right';
import { CustomCube as ItemsIcon } from '../../../icons/custom-cube';
import { CustomUsers as UsersIcon } from '../../../icons/custom-users';
import { OfficeBuilding as TenantsIcon } from '../../../icons/office-building';
import { ColorSwatch as SKUsIcon } from '../../../icons/color-swatch';
import { useAuth } from '../../../hooks/use-auth';
import { SUPER_ADMIN_TENANT } from '../../../types/tenant';

interface DashboardSidebarProps {
  onPin: () => void;
  pinned: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

const adminItems: Item[] = [
  {
    icon: UsersIcon,
    title: 'All Users',
    href: '/users',
  },
  {
    icon: TenantsIcon,
    title: 'Tenants',
    href: '/tenants',
  },
];

const tenantItems: Item[] = [
  {
    icon: JobsIcon,
    title: 'Events',
    href: '/tenants/:tenantId/events',
  },
  {
    icon: ItemsIcon,
    title: 'Items',
    href: '/tenants/:tenantId/items',
  },
  {
    icon: SKUsIcon,
    title: 'SKUs',
    href: '/tenants/:tenantId/skus',
  },
  {
    icon: UsersIcon,
    title: 'Users',
    href: '/tenants/:tenantId/users',
  },
];

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const { tenant } = useAuth();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);

  const items = useMemo(
    () =>
      tenant?.id === SUPER_ADMIN_TENANT.id
        ? adminItems
        : tenantItems.map((i) => ({ ...i, href: i.href.replace(':tenantId', tenant.id) })),
    [tenant],
  );

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    let foundActive = false;

    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            foundActive = true;
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          foundActive = true;
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });

    // If active item not found, try parents
    if (!foundActive) {
      items.forEach((item) => {
        if (item.href) {
          const active = !!matchPath({ path: item.href, end: false }, pathname);
          if (active) {
            setActiveItem(item);
            setOpenedItem(item);
          }
        }
      });
    }
  }, [pathname, items]);

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 270 : 73,
          '& .simplebar-content': {
            height: '100%',
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex',
            },
          },
        },
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2,
          }}
        >
          <List disablePadding>
            {items.map((item) => (
              <DashboardSidebarItem
                active={activeItem?.title === item.title}
                activeHref={activeHref}
                key={item.title}
                onOpen={() => handleOpenItem(item)}
                open={openedItem?.title === item.title && (hovered || pinned)}
                pinned={pinned}
                {...item}
              />
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool,
};
