export enum CalibrationStep {
  INSERT_INFO,
  FOCUS_COMPARISON,
}

export enum CalibrationDialogType {
  CAMERA_STREAM_DIALOG,
  ADJUST_FOCUS_DIALOG,
  REVIEW_DIALOG,
  SECURE_FOCUS_DIALOG,
  APPROVAL_DIALOG,
}

export const getFirstStep = () => CalibrationStep.INSERT_INFO;

export const getLastStep = () => CalibrationStep.FOCUS_COMPARISON;
