import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import PropTypes from 'prop-types';
import { CustomButton } from '../custom-button';
import { CustomDialog, CustomDialogProps } from '../custom-dialog';
import { ArrowRight as ContinueIcon } from '../../../../icons/arrow-right';

type JobErrorDialogProps = Omit<CustomDialogProps, 'title'>;

export const JobErrorDialog: FC<JobErrorDialogProps> = ({ onClose, ...other }) => (
  <CustomDialog onClose={onClose} {...other} title="Task not completed" fullWidth maxWidth="lg">
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gridGap: '1em',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'space-between',
          gridGap: '1em',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: '40px !important',
          }}
        >
          Please try again. If this issue persists, please contact customer service for assistance.
        </Typography>
        <CustomButton onClick={() => onClose({}, 'escapeKeyDown')}>
          Start again
          <ContinueIcon />
        </CustomButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src="/assets/Job-orientationhelp.jpg" alt="Card Orientation Help" />
      </Box>
    </Box>
  </CustomDialog>
);

JobErrorDialog.propTypes = {
  onClose: PropTypes.func,
};
