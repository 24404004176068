import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { X as XIcon } from '../../../../icons/x';
import { PropertyList } from '../../../../components/property-list';
import { PropertyListItem } from '../../../../components/property-list-item';
import { BaseItemWithSKU } from '../../../../types/item';

export enum InfoType {
  Protection,
  Verification,
}

interface InfoContainerProps {
  item: BaseItemWithSKU;
  type: InfoType;
  showClose?: boolean;
  onClose?: () => void;
}

export const InfoContainer: FC<InfoContainerProps> = ({
  item,
  type,
  showClose = false,
  onClose,
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate('/');
    }
  };

  return (
    <Box
      sx={{
        background: '#f2f2f2',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 120,
          pl: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            flex: 1,
          }}
        >
          {type === InfoType.Protection ? 'Protection' : 'Verification'}
        </Typography>
        {Boolean(showClose) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              size="large"
              onClick={handleClose}
              title="Close"
              color="inherit"
              sx={{
                borderRadius: '0 !important',
                background: '#d9d9d9',
                width: 120,
                height: 120,
              }}
            >
              <XIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Divider />
      <Box
        sx={{
          background: '#FFF',
        }}
      >
        <PropertyList>
          <PropertyListItem
            align="vertical"
            label="ID"
            value={item.publicMetadata?.uniqueId}
            color="inherit"
          />
          <Divider />
          <PropertyListItem align="vertical" label="Name" value={item.title} color="inherit" />
          <Divider />
          <PropertyListItem
            align="vertical"
            label="Category"
            value={item.sku?.title}
            color="inherit"
          />
          <Divider />
        </PropertyList>
      </Box>
    </Box>
  );
};

InfoContainer.defaultProps = {
  showClose: false,
};

InfoContainer.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
  type: PropTypes.any.isRequired,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};
