import { useQuery } from 'react-query';
import { useAxios } from './use-axios';

const isOfflineEnabled = () =>
  ['1', 'true', 'True'].includes(process.env.REACT_APP_ENABLE_OFFLINE ?? '');

interface UseOnlineStatusResult {
  isOnline: boolean;
}

export const useOnlineStatus = (): UseOnlineStatusResult => {
  const { axios } = useAxios();

  const { data: isOnline } = useQuery(
    'online',
    async () => {
      if (!isOfflineEnabled()) {
        if (window?.navigator?.onLine === false) {
          return false;
        }

        try {
          const res = await axios.get(`/health?${Math.random().toString(36).substring(7)}`, {
            timeout: 10000,
          });

          return res.status === 200;
        } catch {
          return false;
        }
      }
      return true;
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: !isOfflineEnabled(),
      initialData: true,
    },
  );

  return { isOnline };
};
