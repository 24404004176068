import { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { useAxios } from '../../hooks/use-axios';
import { ResponseData } from '../../types/axios';
import { FlagType, Job } from '../../types/job';

interface JobRevertFlagDialogProps {
  job: Job;
  open: boolean;
  onClose: () => void;
}

export const JobRevertFlagDialog: FC<JobRevertFlagDialogProps> = ({
  job,
  open,
  onClose,
  ...other
}) => {
  const { axios } = useAxios();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      const url = `/jobs/${job.id}/flag`;

      const data = {
        flagAs: FlagType.Neutral,
      };

      return axios.post<ResponseData<Job>>(url, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('jobs');
        queryClient.invalidateQueries('job');
        queryClient.invalidateQueries('item-events');
        toast.success('Event report was reverted');
      },
      onError: () => {
        toast.error('Unable to revert reported event');
      },
      onSettled: () => {
        onClose();
      },
    },
  );

  return (
    <Dialog onClose={onClose} open={open} {...other}>
      <DialogTitle>Revert reported event</DialogTitle>
      <DialogContent>
        <Typography>Reverting will discard the provided feedback on the result.</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          color="primary"
          onClick={() => {
            mutation.mutate();
          }}
          variant="contained"
        >
          Revert
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

JobRevertFlagDialog.defaultProps = {
  open: false,
};

JobRevertFlagDialog.propTypes = {
  // @ts-ignore
  job: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
