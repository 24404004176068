import { useEffect, useRef } from 'react'

export const useTimeout = (callback: any, delay?: number) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!Number.isFinite(delay) || delay <= 0) {
      return () => {}
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id);
  }, [delay])
}