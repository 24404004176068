import type { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

export const InsertCardContent: FC = () => (
  <>
    <Typography variant="h4" px="120px" pt={5}>
      Insert Calibration Card under the&nbsp;Camera
    </Typography>
    <Grid container height="100%" px="120px" pb={5} spacing={5}>
      <Grid
        item
        xs={6}
        height="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gridGap: '1em',
        }}
      >
        <img src="/assets/CalibFlow-align-Incorrect-cardsizeA5.jpg" alt="Align Card Incorrect" />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gridGap: '1em',
        }}
      >
        <img src="/assets/CalibFlow-align-Correct-cardsizeA5.jpg" alt="Align Card Correct" />
      </Grid>
    </Grid>
  </>
);
