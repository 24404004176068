import { Tenant, ADMIN_KEY } from './tenant';

export enum Permission {
  ADMIN = 'ADMIN',
  TENANT_ADMIN = 'TENANT:ADMIN',
  TENANT_USERS_READ = 'TENANT:USERS:READ',
  TENANT_USERS_WRITE = 'TENANT:USERS:WRITE',
  TENANT_ITEMS_READ = 'TENANT:ITEMS:READ',
  TENANT_ITEMS_WRITE = 'TENANT:ITEMS:WRITE',
  TENANT_ITEMS_PROTECT = 'TENANT:ITEMS:PROTECT',
  TENANT_ITEMS_VERIFY = 'TENANT:ITEMS:VERIFY',
  TENANT_STATS = 'TENANT:STATS',
}

export interface Permissions {
  [ADMIN_KEY]?: Permission[];
  [key: string]: Permission[];
}

export interface User {
  id: string;
  email: string;
  name: string;
  permissions?: Permissions;

  // [key: string]: any;
}

export interface ExternalUser extends User {
  tenant?: Tenant;
  tenants?: Tenant[];
}
