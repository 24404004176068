import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { usePermissions } from '../hooks/use-permissions';
import { Permission } from '../types/user';
import { Access } from './access-denied';

interface PermissionsGuardProps {
  permission?: Permission;
  children?: ReactNode;
}

export const PermissionsGuard: FC<PermissionsGuardProps> = ({ permission, children }) => {
  const { allowed } = usePermissions(permission);

  return <Access allowed={allowed}>{children}</Access>;
};

PermissionsGuard.propTypes = {
  permission: PropTypes.any,
  children: PropTypes.node,
};
