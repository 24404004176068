import { useMemo, FC } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { usePopover } from '../../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../../icons/dots-vertical';
import { Flag as FlagIcon } from '../../../../icons/flag';
import { useDialog } from '../../../../hooks/use-dialog';
import { JobFlagDialog } from '../../../../components/job/job-flag-dialog';
import { FlagType, Job } from '../../../../types/job';
import { JobRevertFlagDialog } from '../../../../components/job/job-revert-flag-dialog';

interface JobsMenuProps {
  job: Job;
}

export const JobsMenu: FC<JobsMenuProps> = ({ job }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openFlag, handleOpenFlag, handleCloseFlag] = useDialog();
  const [openRevertFlag, handleOpenRevertFlag, handleCloseRevertFlag] = useDialog();

  const handleFlag = (): void => {
    handleClose();
    handleOpenFlag();
  };

  const handleRevertFlag = (): void => {
    handleClose();
    handleOpenRevertFlag();
  };

  const flagJobAction = useMemo(
    () =>
      job.flag === FlagType.Incorrect ? (
        <MenuItem onClick={handleRevertFlag}>
          <FlagIcon color="action" sx={{ mr: 1 }} />
          Revert Report
        </MenuItem>
      ) : (
        <MenuItem onClick={handleFlag}>
          <FlagIcon color="error" sx={{ mr: 1 }} />
          Report Event
        </MenuItem>
      ),

    [job],
  );

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {flagJobAction}
      </Menu>
      <JobFlagDialog job={job} open={openFlag} onClose={handleCloseFlag} />
      <JobRevertFlagDialog job={job} open={openRevertFlag} onClose={handleCloseRevertFlag} />
    </>
  );
};

JobsMenu.propTypes = {
  job: PropTypes.any.isRequired,
};
