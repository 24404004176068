import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from '../../components/auth-guard';
import { GuestGuard } from '../../components/guest-guard';
import { LoadingScreen } from '../../components/loading-screen';
import { Jobs } from '../../containers/jobs';
import { MainLayout } from './containers/main-layout';
import { FlowProtection, ProtectionStep } from './containers/flow-protection';
import { FlowVerification, VerificationStep } from './containers/flow-verification';
import { Flow } from './containers/flow';
import { Calibration } from './containers/calibration';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Not found pages
const NotFound = Loadable(
  lazy(() =>
    import('../../containers/not-found').then((module) => ({
      default: module.NotFound,
    })),
  ),
);

// Auth pages
const Login = Loadable(
  lazy(() =>
    import('../../containers/login').then((module) => ({
      default: module.Login,
    })),
  ),
);

const ErrorPage = Loadable(
  lazy(() =>
    import('../../containers/error-page').then((module) => ({
      default: module.ErrorPage,
    })),
  ),
);

const NoPermissions = Loadable(
  lazy(() =>
    import('../../containers/no-permissions').then((module) => ({
      default: module.NoPermissions,
    })),
  ),
);

const routes: PartialRouteObject[] = [
  // {
  //   path: '/',
  //   element: <Navigate to="/" replace />,
  // },
  {
    path: '500',
    element: (
      <GuestGuard>
        <ErrorPage />
      </GuestGuard>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'no-permissions',
    element: (
      <AuthGuard>
        <NoPermissions />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'calibration',
        element: <Calibration />,
      },
      {
        path: 'events',
        element: <Jobs />,
      },
      {
        element: <Flow />,
        children: [
          {
            path: 'protection',
            children: [
              {
                path: '/',
                element: <Navigate to="/protection/front" replace />,
              },
              {
                path: 'front',
                element: <FlowProtection step={ProtectionStep.Front} />,
              },
              {
                path: 'back',
                element: <FlowProtection step={ProtectionStep.Back} />,
              },
              {
                path: 'analyzing',
                element: <FlowProtection step={ProtectionStep.Analyzing} />,
              },
              {
                path: 'success',
                element: <FlowProtection step={ProtectionStep.Protected} />,
              },
              {
                path: 'fail',
                element: <FlowProtection step={ProtectionStep.UnableToProtect} />,
              },
            ],
          },
          {
            path: 'verification',
            children: [
              {
                path: '/',
                element: <Navigate to="/verification/front" replace />,
              },
              {
                path: 'front',
                element: <FlowVerification step={VerificationStep.Front} />,
              },
              {
                path: 'back',
                element: <FlowVerification step={VerificationStep.Back} />,
              },
              {
                path: 'analyzing',
                element: <FlowVerification step={VerificationStep.Analyzing} />,
              },
              {
                path: 'success',
                element: <FlowVerification step={VerificationStep.Verified} />,
              },
              {
                path: 'fail',
                element: <FlowVerification step={VerificationStep.NotVerified} />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
