import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ButtonProps } from '@material-ui/core';
import { ArrowRight as ContinueIcon } from '../../../../icons/arrow-right';
import { CustomButton } from '../custom-button';

export const ButtonContinue: FC<ButtonProps> = ({ children, ...other }) => (
  <CustomButton {...other}>
    {children || 'Continue'}
    <ContinueIcon />
  </CustomButton>
);

ButtonContinue.propTypes = {
  children: PropTypes.any,
};
