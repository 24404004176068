import { FC } from 'react';
import { Box, Container } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import { LoadingDialog } from '../components/loading-dialog';
import { useScanner } from '../hooks/use-scanner';

export const Flow: FC = () => {
  const { isItemLoading } = useScanner();
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: 'background.default',
        flexGrow: 1,
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: '0 !important',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'grid',
            gridTemplateColumns: '70% 30%',
            gridTemplateRows: '60% 40%',
          }}
        >
          <Outlet />
        </Box>
      </Container>
      <LoadingDialog open={isItemLoading} title="Loading item" />
    </Box>
  );
};
