import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonProps, Typography } from '@material-ui/core';
import { CalibrationStep, getFirstStep } from '../../utils/calibration-helper';
import { ArrowLeft as BackIcon } from '../../../../icons/arrow-left';

interface ButtonPrevProps extends ButtonProps {
  step: CalibrationStep;
}

export const ButtonPrev: FC<ButtonPrevProps> = ({ step, ...other }) => {
  if (step === getFirstStep()) {
    return <></>;
  }

  return (
    <Button
      color="secondary"
      variant="contained"
      size="large"
      {...other}
      sx={{
        height: '100%',
        width: 240,
        backgroundColor: `#D9D9D9 !important`,
        mr: '.5rem',
      }}
    >
      <Typography
        variant="subtitle1"
        color="#4D4D4D"
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gridGap: '.5em',
        }}
      >
        <BackIcon />
        Back
      </Typography>
    </Button>
  );
};

ButtonPrev.propTypes = {
  step: PropTypes.number,
};
