import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Socket, connect } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { BaseItemWithSKU, Item } from '../../../types/item';
import { ScannedItem, LoadedItem } from '../types/scanned-item';
import { useAuth } from '../../../hooks/use-auth';
import logger, { permaLogger } from '../../../utils/logger';
import { useAxios } from '../../../hooks/use-axios';
import { SKU } from '../../../types/sku';
import {
  CreateRemoteFileRequestData,
  RemoteFile,
  RemoteFileTTL,
  RemoteFileType,
} from '../../../types/remote-file';
import { BackendType, ListResponse, ResponseData } from '../../../types/axios';
import { CAPTURE_TIMEOUT } from '../utils/flow-helper';

interface LastCalibrationResponse {
  calibratedAt: number;
}

interface CalibrateRequestData {
  cardId: string;
  queryFile: RemoteFile;
  alignedFile: RemoteFile;
}
interface CalibrateResponse {
  calibration: string;
  cardId: string;
  calibratedAt: number;
  queryFile: RemoteFile;
  alignedFile: RemoteFile;
  data: any;
}

interface CaptureImageData {
  title: string;
  skuWidth: string;
  skuHeight: string;
  skuDepth: string;
  offsetTop?: string;
  offsetRight?: string;
  offsetBottom?: string;
  offsetLeft?: string;
  isInSleeve?: boolean;
  isHDR?: boolean;
  side?: string;
  debug?: string;
}

interface CaptureImageRequestData extends CaptureImageData {
  remoteImage: RemoteFile;
  remoteFP?: RemoteFile;
  remoteThumbnail?: RemoteFile;
}

interface CaptureImageOptions extends CaptureImageData {
  createFP?: boolean;
  createThumbnail?: boolean;
}

interface CameraImage {
  image: RemoteFile;
  thumbnail?: RemoteFile;
  previewBase64?: string;
  isHDR?: boolean;
  side?: string;
}

export enum ECameraStreamMode {
  NORMAL = 'normal',
  CALIBRATION = 'calibration',
}

export enum ESocketEvent {
  ScanEvent = 'scan_event',
  ScanEventError = 'scan_event_error',
  PhotoCapturedEvent = 'photo_captured_event',
  CameraAvailableEvent = 'camera_available_event',
}

export enum MessageAction {
  Login = 'login',
  ItemScan = 'item_scan',
  Calibration = 'calibration',
}

type MessageData = {
  email?: string;
  password?: string;
} & {
  calibration: any;
  cardId: any;
} & ScannedItem;

export interface ScanMessage {
  action: MessageAction;
  data: MessageData;
}

export type PhotoMessage = CameraImage;

interface State {
  isInitialized: boolean;
  isConnected: boolean;
  socket: Socket;
  isItemLoading?: boolean;
  item?: LoadedItem;
  isImageLoading: boolean;
  error?: string;
  calibratedAt?: Date;
  isCalibrating?: boolean;
}

interface ScannerContextValue extends State {
  loadItem: (item: ScannedItem, callback?: (item: LoadedItem, error?: any) => void) => void;
  setItem: (item: LoadedItem) => void;
  resetItem: (noRedirect?: boolean) => void;
  captureImage: (
    optiopns: CaptureImageOptions,
    callback?: (data?: CameraImage, error?: any) => void,
  ) => void;
  abortImageCapture: () => void;
  startStream: () => void;
  stopStream: () => void;
  getStream: (mode?: ECameraStreamMode) => string;
  calibrate: (cardId?: string, callback?: (data?: CalibrateResponse, error?: any) => void) => void;
  calibrationDone: (date: Date) => void;
}

interface ScannerProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    item?: Item;
    socket: Socket;
    error?: string;
  };
};

type ConnectAction = {
  type: 'CONNECT';
};

type DisconnectAction = {
  type: 'DISCONNECT';
};

type LoadItemAction = {
  type: 'LOAD_ITEM';
};

type SetItemAction = {
  type: 'SET_ITEM';
  payload: {
    item: LoadedItem;
  };
};

type ResetItemAction = {
  type: 'RESET_ITEM';
};

type CaptureImageAction = {
  type: 'CAPTURE_IMAGE';
};

type ImageCapturedAction = {
  type: 'IMAGE_CAPTURED';
};

type ImageCaptureErrorAction = {
  type: 'IMAGE_CAPTURE_ERROR';
  payload: {
    error: string;
  };
};

type CalibrateAction = {
  type: 'CALIBRATE';
};

type CalibrationDoneAction = {
  type: 'CALIBRATION_DONE';
  payload: {
    date?: Date;
  };
};

type Action =
  | InitializeAction
  | ConnectAction
  | DisconnectAction
  | LoadItemAction
  | SetItemAction
  | ResetItemAction
  | CaptureImageAction
  | ImageCapturedAction
  | ImageCaptureErrorAction
  | CalibrateAction
  | CalibrationDoneAction;

const initialState: State = {
  isInitialized: false,
  isConnected: false,
  socket: null,
  isImageLoading: false,
  isCalibrating: false,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { socket, item, error } = action.payload;

    if (state.socket) {
      state.socket.off('connect');
      state.socket.off('disconnect');
      state.socket.off(ESocketEvent.ScanEvent);
      state.socket.off(ESocketEvent.ScanEventError);
      state.socket.off(ESocketEvent.PhotoCapturedEvent);
      state.socket.off(ESocketEvent.CameraAvailableEvent);
      state.socket.close();
    }

    return {
      ...state,
      isInitialized: true,
      socket,
      isItemLoading: false,
      item,
      error,
      isImageLoading: false,
    };
  },
  CONNECT: (state: State): State => ({
    ...state,
    isConnected: true,
  }),
  DISCONNECT: (state: State): State => ({
    ...state,
    isConnected: false,
  }),
  LOAD_ITEM: (state: State): State => ({
    ...state,
    isItemLoading: true,
  }),
  SET_ITEM: (state: State, action: SetItemAction): State => {
    const { item } = action.payload;

    return {
      ...state,
      item,
      isItemLoading: false,
    };
  },
  RESET_ITEM: (state: State): State => ({
    ...state,
    item: undefined,
    isItemLoading: false,
  }),
  CAPTURE_IMAGE: (state: State): State => ({
    ...state,
    isImageLoading: true,
  }),
  IMAGE_CAPTURED: (state: State): State => ({
    ...state,
    isImageLoading: false,
  }),
  IMAGE_CAPTURE_ERROR: (state: State, action: ImageCaptureErrorAction): State => {
    const { error } = action.payload;
    return {
      ...state,
      isImageLoading: false,
      error,
    };
  },
  CALIBRATE: (state: State): State => ({
    ...state,
    isCalibrating: true,
  }),
  CALIBRATION_DONE: (state: State, action: CalibrationDoneAction): State => {
    const { date } = action.payload;
    return {
      ...state,
      calibratedAt: date,
      isCalibrating: false,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const ScannerContext = createContext<ScannerContextValue>({
  ...initialState,
  loadItem: () => {},
  setItem: () => {},
  resetItem: () => {},
  captureImage: () => {},
  abortImageCapture: () => {},
  startStream: () => {},
  stopStream: () => {},
  getStream: () => '',
  calibrate: () => {},
  calibrationDone: () => {},
});

const E_SKU_NOT_FOUND = 'Item SKU not found';
const E_NON_UNIQUE_ID = 'Too many items with same unique ID';

export const ScannerProvider: FC<ScannerProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { isAuthenticated, tenant, logout } = useAuth();
  const { axios: axiosScanner } = useAxios(true, BackendType.SCANNER);
  const { axios: axiosBackend } = useAxios(false, BackendType.BACKEND);
  const [abortController, setAbortController] = useState<AbortController>();

  const loadItemMutation = useMutation(
    async (scannedItem: ScannedItem) => {
      let item: LoadedItem;

      const {
        data: {
          data: { data: items },
        },
      } = await axiosBackend.get<ResponseData<ListResponse<Item>>>(`/items/`, {
        signal: abortController.signal,
        params: {
          'globalFilter[tenant]': tenant?.id,
          'globalFilter[publicMetadata]': { uniqueId: scannedItem.uniqueId },
          'globalFilter[isProtected]': true,
        },
      });
      const completedItems = items.filter(
        (i) => i.fingerprints?.length > 0 && !i.publicMetadata?.deactivated,
      );
      if (completedItems.length === 0) {
        const {
          data: {
            data: { data: skus },
          },
        } = await axiosBackend.get<ResponseData<ListResponse<SKU>>>(`/skus`, {
          signal: abortController.signal,
          params: {
            'globalFilter[tenant]': tenant?.id,
            'globalFilter[publicMetadata]': { serialId: scannedItem.skuSerialId },
          },
        });
        if (skus.length === 1) {
          const [sku] = skus;
          item = {
            title: scannedItem.title,
            publicMetadata: { uniqueId: scannedItem.uniqueId },
            sku,
          } as BaseItemWithSKU;
        } else if (skus.length > 1) {
          toast.error('Too many SKUs with same ID');
          throw new Error(E_SKU_NOT_FOUND);
        } else {
          toast.error('Item SKU not found');
          throw new Error(E_SKU_NOT_FOUND);
        }
      } else if (completedItems.length !== 1) {
        toast.error('Too many items with same ID');
        throw new Error(E_NON_UNIQUE_ID);
      } else {
        [item] = completedItems;
      }

      if (scannedItem.debug) {
        item.debug = scannedItem.debug;
      }

      return item;
    },
    {
      onMutate: () => {
        permaLogger('[SCANNER/LOAD_ITEM] Loading item');
      },
      onSuccess: (item) => {
        permaLogger('[SCANNER/LOAD_ITEM] Item loaded', item);
      },
      onError: (err) => {
        logger('Loading item/SKU error:', err);
        if (typeof err === 'string' && ![E_NON_UNIQUE_ID, E_SKU_NOT_FOUND].includes(err)) {
          toast.error('Cannot load item');
        }
      },
    },
  );

  const createRemoteFilePost = async (title?: string) => {
    if (!title) {
      return undefined;
    }

    const url = '/files';
    const data: CreateRemoteFileRequestData = {
      tenantId: tenant.id,
      title,
      type: RemoteFileType.GC,
      ttl: RemoteFileTTL.PERMANENT,
    };

    return axiosBackend.post<ResponseData<RemoteFile>>(url, data, {
      signal: abortController.signal,
    });
  };

  const captureImageMutation = useMutation(
    async (options: CaptureImageOptions) => {
      const { title, side, isHDR, createThumbnail, createFP, ...other } = options;

      const prefix = `${title}_${side}_`;
      const titles = [`${prefix}${isHDR ? 'HDR' : 'IMG'}.jpg`];
      titles.push(createFP ? `${prefix}FP.jpg` : undefined);
      titles.push(createThumbnail ? `${prefix}Thumbnail.jpg` : undefined);

      const files = await Promise.all(titles.map(createRemoteFilePost));
      const [remoteImage, remoteFP, remoteThumbnail] = files.map((f) => f?.data?.data);

      const data: CaptureImageRequestData = {
        title,
        isHDR,
        side,
        ...other,
        remoteImage,
        remoteThumbnail,
        remoteFP,
      };

      return axiosScanner.post<CameraImage>('/photos/snap', data, {
        timeout: CAPTURE_TIMEOUT,
        signal: abortController.signal,
      });
    },
    {
      onError: (err) => {
        logger('Error while capturing image', err);
        dispatch({
          type: 'IMAGE_CAPTURE_ERROR',
          payload: {
            error: err as string,
          },
        });
      },
    },
  );

  const setItem = (item?: LoadedItem) => {
    dispatch({
      type: 'SET_ITEM',
      payload: {
        item,
      },
    });
  };

  const resetItem = (noRedirect?: boolean) => {
    if (!noRedirect) {
      navigate('/');
    }
    dispatch({
      type: 'RESET_ITEM',
    });
  };

  const loadItem = (
    scannedItem: ScannedItem,
    callback?: (item: LoadedItem, error?: any) => void,
  ) => {
    dispatch({ type: 'LOAD_ITEM' });
    loadItemMutation.mutate(scannedItem, {
      onSuccess: (item) => {
        if (callback) {
          callback(item);
        }
        dispatch({
          type: 'SET_ITEM',
          payload: {
            item,
          },
        });
      },
      onError: (error) => {
        if (callback) {
          callback(undefined, error);
        }
        resetItem();
      },
    });
  };

  const captureImage = async (
    options: CaptureImageOptions,
    callback?: (data?: CameraImage, error?: any) => void,
  ) => {
    dispatch({ type: 'CAPTURE_IMAGE' });
    try {
      const res = await captureImageMutation.mutateAsync(options);
      if (callback) {
        callback(res.data);
      }
    } catch (error) {
      if (callback) {
        callback(undefined, error);
      }
    } finally {
      dispatch({ type: 'IMAGE_CAPTURED' });
    }
  };

  const abortImageCapture = () => {
    abortController.abort();
    setAbortController(new AbortController());
  };

  const startStreamMutation = useMutation(async () => axiosScanner.post('/photos/stream/start'));

  const startStream = () => {
    startStreamMutation.mutate();
  };

  const stopStreamMutation = useMutation(async () => axiosScanner.post('/photos/stream/stop'));

  const stopStream = () => {
    stopStreamMutation.mutate();
  };

  const getStream = useCallback(
    (mode?: ECameraStreamMode) =>
      `${axiosScanner.defaults.baseURL}/photos/stream?mode=${mode || ECameraStreamMode.NORMAL}`,
    [axiosScanner.defaults.baseURL],
  );

  const calibrationDone = (date?: Date) => {
    logger('[SCANNER/CALIRATION_DONE] Calibration done', date);
    dispatch({ type: 'CALIBRATION_DONE', payload: { date } });
  };

  const calibrateMutation = useMutation(async (cardId: string) => {
    const timestamp = Date.now();
    const files = await Promise.all(
      [`${timestamp}_calibration_query.jpg`, `${timestamp}_calibration_aligned.jpg`].map(
        createRemoteFilePost,
      ),
    );
    const [queryFile, alignedFile] = files.map((f) => f.data?.data);

    const data: CalibrateRequestData = {
      cardId,
      queryFile,
      alignedFile,
    };

    return axiosScanner.post<CalibrateResponse>('/calibrate', data);
  });

  const calibrate = (
    cardId?: string,
    callback?: (data?: CalibrateResponse, error?: any) => void,
  ) => {
    logger('[SCANNER/CALIBRATE] Start Calibration');
    dispatch({ type: 'CALIBRATE' });

    calibrateMutation.mutate(cardId, {
      onSuccess: ({ data }) => {
        toast.success('Calibration saved');
        calibrationDone(new Date(data.calibratedAt * 1000));

        if (callback) {
          callback(data);
        }
      },
      onError: (error) => {
        logger('[SCANNER/CALIBRATE] Error', error);
        toast.error('Something went wrong while calibrating');
        if (callback) {
          callback(undefined, error);
        }
      },
    });
  };

  useQuery(
    ['last-calibration'],
    async () => {
      const { data: result } = await axiosScanner.get<LastCalibrationResponse>('/lastCalibration');

      const newCalibrationDate = result?.calibratedAt
        ? new Date(result.calibratedAt * 1000)
        : undefined;
      dispatch({ type: 'CALIBRATION_DONE', payload: { date: newCalibrationDate } });

      return result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const initialize = () => {
      try {
        logger('[SOCKET] Initiating on url', process.env.REACT_APP_SCANNER_SOCKET_URL);
        const socket = connect(process.env.REACT_APP_SCANNER_SOCKET_URL, {
          upgrade: false,
          transports: ['websocket'],
        });

        socket.on('connect', () => {
          logger('[SOCKET/CONNECT]');
          dispatch({
            type: 'CONNECT',
          });
        });
        socket.on('error', (err) => {
          logger('[SOCKET/ERROR]', err);
        });
        socket.on('connect_error', (err) => {
          logger('[SOCKET/CONNECT_ERROR]', err);
          dispatch({
            type: 'DISCONNECT',
          });
        });
        socket.on('disconnect', () => {
          logger('[SOCKET/CONNECT]');
          dispatch({
            type: 'DISCONNECT',
          });
        });

        socket.on(ESocketEvent.ScanEvent, ({ action, data }: ScanMessage) => {
          logger(`[SOCKET/SCAN_EVENT]`, action, data);
          switch (action) {
            default:
              // logger('[SOCKET/SCAN_EVENT] Uknown message action', message);
              break;
            case MessageAction.Login: {
              if (isAuthenticated) {
                logout();
              }
              break;
            }
            case MessageAction.Calibration: {
              if (isAuthenticated) {
                // navigate(`/calibration?cardId=${data.cardId ?? 1}`, { replace: true });
                window.location.href = `/calibration?cardId=${data.cardId ?? 1}`;
              } else {
                toast.error('You must be logged in to start calibratation');
              }
              break;
            }
          }
        });

        socket.on(ESocketEvent.PhotoCapturedEvent, (data: PhotoMessage) => {
          logger(`[SOCKET/PHOTO_CAPTURED_EVENT]`, data);
        });

        socket.on(ESocketEvent.CameraAvailableEvent, (data: PhotoMessage) => {
          logger(`[SOCKET/CAMERA_AVAILABLE_EVENT]`, data);
        });

        socket.on(ESocketEvent.ScanEventError, () => {
          logger(`[SOCKET/SCAN_EVENT_ERROR] Error while scanning QR code`);

          toast.error('Wrong format of QR code data');
        });

        dispatch({
          type: 'INITIALIZE',
          payload: {
            socket,
          },
        });
      } catch (err) {
        logger('Error', err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            socket: null,
            error: err as string,
          },
        });
      }
    };

    initialize();

    return () => {
      if (state.socket) {
        state.socket.off('connect');
        state.socket.off('disconnect');
        state.socket.off(ESocketEvent.ScanEvent);
        state.socket.off(ESocketEvent.ScanEventError);
        state.socket.off(ESocketEvent.PhotoCapturedEvent);
        state.socket.off(ESocketEvent.CameraAvailableEvent);
      }
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (state.item) {
        if ((state.item as Item)?.id) {
          navigate('/verification/front', { replace: true });
        } else {
          navigate('/protection/front', { replace: true });
        }
      }
    }
  }, [isAuthenticated, state.item]);

  useEffect(() => {
    if (axiosScanner || axiosBackend) {
      setAbortController(new AbortController());
    }
  }, [axiosScanner, axiosBackend]);

  return (
    <ScannerContext.Provider
      value={{
        ...state,
        loadItem,
        setItem,
        resetItem,
        captureImage,
        abortImageCapture,
        startStream,
        stopStream,
        getStream,
        calibrate,
        calibrationDone,
      }}
    >
      {children}
    </ScannerContext.Provider>
  );
};

ScannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
