import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Card, CardContent, Container, Grid, Toolbar } from '@material-ui/core';
import { LoginJwt } from '../../../components/auth/login-jwt';
import { Logo } from '../../../components/logo';
import { useSettings } from '../../../contexts/settings-context';
import { getHelmetTitle } from '../../../utils/utils';

export const Login: FC = () => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Login | {getHelmetTitle()}</title>
      </Helmet>
      <AppBar elevation={0} sx={{ backgroundColor: 'background.paper' }}>
        <Container maxWidth="md">
          <Toolbar disableGutters sx={{ height: 64 }}>
            <RouterLink to="/">
              <Logo variant={settings.theme === 'dark' ? 'light' : 'dark'} />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px',
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <Card sx={{ backgroundColor: 'background.default' }} elevation={0}>
                  <CardContent
                    sx={{
                      display: 'grid',
                      gridGap: '1em',
                    }}
                  >
                    <LoginJwt />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
