import { useState, useEffect, useMemo } from 'react';
import type { FC, ElementType } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer, List } from '@material-ui/core';
import { HorizontalSplitRounded as JobsIcon } from '@material-ui/icons';
import { DashboardNavbarMenuItem } from '../../../components/dashboard-navbar-menu-item';
import { CustomCube as ItemsIcon } from '../../../icons/custom-cube';
import { CustomUsers as UsersIcon } from '../../../icons/custom-users';
import { OfficeBuilding as TenantsIcon } from '../../../icons/office-building';
import { ColorSwatch as SKUsIcon } from '../../../icons/color-swatch';
import { SUPER_ADMIN_TENANT } from '../../../types/tenant';
import { useAuth } from '../../../hooks/use-auth';

interface DashboardNavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

const adminItems: Item[] = [
  {
    icon: UsersIcon,
    title: 'All Users',
    href: '/users',
  },
  {
    icon: TenantsIcon,
    title: 'Tenants',
    href: '/tenants',
  },
];

const tenantItems: Item[] = [
  {
    icon: JobsIcon,
    title: 'Events',
    href: '/tenants/:tenantId/events',
  },
  {
    icon: ItemsIcon,
    title: 'Items',
    href: '/tenants/:tenantId/items',
  },
  {
    icon: SKUsIcon,
    title: 'SKUs',
    href: '/tenants/:tenantId/skus',
  },
  {
    icon: UsersIcon,
    title: 'Users',
    href: '/tenants/:tenantId/users',
  },
];

export const DashboardNavbarMenu: FC<DashboardNavbarMenuProps> = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const { tenant } = useAuth();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState('');

  const items = useMemo(
    () =>
      tenant?.id === SUPER_ADMIN_TENANT.id
        ? adminItems
        : tenantItems.map((i) => ({ ...i, href: i.href.replace(':tenantId', tenant.id) })),
    [tenant],
  );

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    let foundActive = false;

    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            foundActive = true;
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          foundActive = true;
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });

    // If active item not found, try parents
    if (!foundActive) {
      items.forEach((item) => {
        if (item.href) {
          const active = !!matchPath({ path: item.href, end: false }, pathname);
          if (active) {
            setActiveItem(item);
            setOpenedItem(item);
          }
        }
      });
    }
  }, [pathname, items]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#2B2F3C',
          color: '#B2B7C8',
          display: 'flex',
          flexDirection: 'column',
          top: 64,
          maxHeight: 'calc(100% - 64px)',
          width: '100vw',
        },
      }}
    >
      <List>
        {activeItem &&
          items.map((item) => (
            <DashboardNavbarMenuItem
              active={activeItem?.title === item.title}
              activeHref={activeHref}
              key={item.title}
              onClose={onClose}
              onOpenItem={() => handleOpenItem(item)}
              open={openedItem?.title === item.title}
              {...item}
            />
          ))}
      </List>
    </Drawer>
  );
};

DashboardNavbarMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
