export enum FilterOperator {
  EQUAL = 'equal',
  NOT_EQUAL = 'notEqual',
  CONTAINS = 'contains',
  IN_ARRAY = 'inArray',
  NOT_IN_ARRAY = 'notInArray',
  BETWEEN = 'between',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
  MORE_THAN = 'moreThan',
  MORE_THAN_OR_EQUAL = 'moreThanOrEqual',
}
