import type { FC } from 'react';
import { ETemplateType } from '../templates/types';
import { Login as LoginDefault } from '../templates/default/containers/login';
import { Login as LoginEbayOperator } from '../templates/ebay-operator/containers/login';

export const Login: FC = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <LoginDefault />;
    case ETemplateType.EBAY_OPERATOR:
      return <LoginEbayOperator />;
  }
};
