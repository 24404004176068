import { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { useAuth } from '../../../hooks/use-auth';
import { usePopover } from '../../../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';
import { Logout as LogoutIcon } from '../../../icons/logout';
import { lightNeutral } from '../../../colors';
import { User } from '../../../types/user';

interface AccountPopoverProps {
  currentUser?: User;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { currentUser, ...other } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2,
        }}
        {...other}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none',
            },
            flex: 1,
            ml: 1,
            minWidth: 120,
          }}
        >
          <div>
            <Typography
              sx={{
                color: lightNeutral[500],
              }}
              variant="caption"
            >
              {currentUser.email}
            </Typography>
            <Typography sx={{ color: 'primary.contrastText' }} variant="subtitle2">
              {currentUser.name}
            </Typography>
          </div>
          <ChevronDownIcon
            sx={{
              color: 'primary.contrastText',
              ml: 1,
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 260,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <List>
          {/* User */}
          <ListItem divider>
            <ListItemText primary={currentUser.name} secondary={currentUser.email} />
          </ListItem>
          {/* Logout */}
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  // @ts-ignore
  currentUser: PropTypes.object.isRequired,
};
