import { FC, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useScanner } from '../hooks/use-scanner';
import { ScanMessage, MessageAction, ESocketEvent } from '../contexts/scanner-context';
import { useAuth } from '../../../hooks/use-auth';
import { LoginNavbar } from '../components/auth/login-navbar';
import { Key as KeyIcon } from '../../../icons/key';
import { getHelmetTitle } from '../../../utils/utils';

export const Login: FC = () => {
  const { isAuthenticated, login, error } = useAuth();
  const { socket } = useScanner();

  const messageHandler = useCallback(
    ({ action, data }: ScanMessage) => {
      if (action === MessageAction.Login) {
        if (!isAuthenticated) {
          login(data.email, data.password);
        }
      }
    },
    [login, isAuthenticated],
  );

  useEffect(() => {
    if (!isAuthenticated && error) {
      toast.error(error);
    }
  }, [isAuthenticated, error]);

  useEffect(() => {
    if (socket) {
      socket.on(ESocketEvent.ScanEvent, messageHandler);
    }

    return () => {
      socket?.off(ESocketEvent.ScanEvent, messageHandler);
    };
  }, [socket, messageHandler]);

  return (
    <>
      <Helmet>
        <title>Login | {getHelmetTitle()}</title>
      </Helmet>
      <LoginNavbar />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '120px',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridGap: '1em',
            justifyItems: 'center',
            alignItems: 'center',
            marginTop: -25,
          }}
        >
          <KeyIcon sx={{ fontSize: '150px' }} />
          <Typography variant="h4">Log In/Out With QR Code</Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '34px !important',
            }}
          >
            Please scan QR code associated with account/device to log in (or log out)
          </Typography>
        </Box>
      </Box>
    </>
  );
};
